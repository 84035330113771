import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Artist, ArtistSort } from '../../models/artist/artist';
import {
  Organisation,
  OrganisationSort,
} from '../../models/organisation/organisation';
import {
  OrganisationCall,
  OrganisationCallSort,
} from '../../models/organisation/organisation-call';
import { User, UserList } from '../../models/user/user';
import { UserInvited } from '../../models/user/user-invited';
import { UserRole } from '../../models/user/user-role';
import { EntityList } from '../../models/entity-list/entity-list';
import { ConfigService } from '../config/config.service';
import { CacheService } from '../cache/cache.service';
import { EntityListHelper } from '../../helpers/entity-list.helper';
import { AppSettings } from '../../statics/app.settings';
import { CustomURLEncoder } from '../../helpers/utils';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private entityListHelper: EntityListHelper,
    private cacheService: CacheService,
    private http: HttpClient,
    private config: ConfigService
  ) {}

  getUsers(
    role?: UserRole,
    nextToken?: string,
    limit = AppSettings.paginationUsersLimit
  ): Observable<UserList> {
    let params = new HttpParams({
      encoder: new CustomURLEncoder(),
      fromObject: {
        limit: `${limit}`,
      },
    });
    if (role) {
      params = params.append('role', `${role}`);
    }
    if (nextToken) {
      params = params.append('nextToken', nextToken);
    }
    return this.cacheService.get(`/users`, params);
  }

  getUserRoles(username: string): Observable<UserRole[]> {
    return this.cacheService.get(`/users/${username}/roles`);
  }

  createUserRole(username: string, role: UserRole): Observable<UserRole[]> {
    return this.http
      .post<UserRole[]>(
        `${this.config.get().backendUrl}/users/${username}/roles`,
        { role }
      )
      .pipe(
        tap(() => {
          this.cacheService.clear(`/users/${username}/roles`);
        })
      );
  }

  deleteUserRole(username: string, role: UserRole): Observable<UserRole[]> {
    return this.http
      .delete<null>(
        `${this.config.get().backendUrl}/users/${username}/roles/${role}`
      )
      .pipe(
        tap(() => {
          this.cacheService.clear(`/users/${username}/roles`);
        })
      );
  }

  getUserArtists(
    userUuid: string,
    sort: ArtistSort = 'name',
    reverse: boolean = false
  ): Observable<EntityList<Artist>> {
    return this.entityListHelper.get(`/users/${userUuid}/artists`, 0, 0, {
      sort: `${sort}`,
      reverse: `${reverse}`,
    });
  }

  getUserOrganisations(
    userUuid: string,
    sort: OrganisationSort = 'name',
    reverse: boolean = false
  ): Observable<EntityList<Organisation>> {
    return this.entityListHelper.get(`/users/${userUuid}/organisations`, 0, 0, {
      sort: `${sort}`,
      reverse: `${reverse}`,
    });
  }

  getUserOrganisationCalls(
    userUuid: string,
    sort: OrganisationCallSort = 'datePublished',
    reverse: boolean = false
  ): Observable<EntityList<OrganisationCall>> {
    return this.entityListHelper.get(
      `/users/${userUuid}/organisationcalls`,
      0,
      0,
      {
        sort: `${sort}`,
        reverse: `${reverse}`,
      }
    );
  }

  getUserInvites(userUuid: string): Observable<EntityList<UserInvited>> {
    return this.entityListHelper.get(`/users/${userUuid}/invites`, 0, 0);
  }

  completeMigration(
    v2Id: string,
    email: string,
    givenName: string
  ): Observable<null> {
    return this.http.post<null>(
      `${this.config.get().backendUrl}/users/completeMigration`,
      {
        v2Id,
        email,
        firstName: givenName,
      }
    );
  }

  /** Manually verifies a user by uuid */
  verifyUser(username: string): Observable<User> {
    return this.http
      .post<User>(
        `${this.config.get().backendUrl}/users/${username}/verify`,
        null
      )
      .pipe(
        tap(() => {
          this.cacheService.clear(`/users`);
        })
      );
  }

  /** Deletes a user */
  deleteUser(userUuid: string) {
    return this.http.delete<null>(
      `${this.config.get().backendUrl}/users/${userUuid}`
    );
  }

  /** Updates the newsletters a user is subscribed to */
  updateUserNewsletterSubscriptions(
    username: string,
    newsletterUuids: string[]
  ): Observable<null> {
    return this.http.post<null>(
      `${this.config.get().backendUrl}/users/${username}/newsletters`,
      { newsletterUuids }
    );
  }
}
