<div class="_modal--error" *ngIf="humanError">
  <header class="_modal-header">
    <h3 class="_modal-title" *ngIf="humanError.title">{{humanError.title}}</h3>
    <h3 class="_modal-title" *ngIf="!humanError.title" i18n>Sorry, er ging iets fout...</h3>
  </header>
  <section class="_modal-content">
    <p class="error-message">{{humanError.message|key:'SERVER_ERROR'}}</p>
  </section>
  <section class="_modal-actions">
    <button
      #closeButton
      type="button"
      class="_button--danger"
      i18n
      (click)="close()"
    >Sluiten</button>
  </section>
</div>