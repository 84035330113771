import { HttpParameterCodec, HttpParams } from '@angular/common/http';

export class CustomURLEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(key: string): string {
    return encodeURIComponent(key);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(key: string) {
    return decodeURIComponent(key);
  }
}

/** Returns a HttpParams object with params omitted when they're 0, null, undefined, … */
export const createHttpParams = (params: Record<string, any>): HttpParams  => {
  let httpParams: HttpParams = new HttpParams();
  Object.keys(params).forEach(param => {
    if (params[param]) {
      if (params[param] instanceof Array) {
        params[param].forEach(value => {
          httpParams = httpParams.append(`${param}`, value);
        });
      } else {
        httpParams = httpParams.append(param, params[param]);
      }
    }
  });
  return httpParams;
};

/** Lowercases all strings in an array but the first */
export const lowercaseAllButFirst = (items: string[]) => items.map((item, i) =>
  i === 0 ? item : item.toLowerCase());
