import { Injectable } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import { ContentService } from '../services/content/content.service';

@Injectable({
  providedIn: 'root',
})
export class IsContentLoaded  {
  constructor(private contentService: ContentService) {}

  canMatch(route: Route, segments: UrlSegment[]) {
    return this.contentService.isContentLoaded();
  }
}
