<!-- Intro -->
<section class="intro">
  <div
    *ngIf="content?.intro.text"
    class="intro-text _title--large"
    [innerHTML]="content?.intro.text"
  ></div>
  <div class="intro-links">
    <a
      *ngIf="content?.intro.linkLabel && content?.intro.linkUrl"
      href="{{content?.intro.linkUrl}}"
      class="intro-link _button--medium _button--outline"
    >{{content?.intro.linkLabel}}</a>
    <a
      *ngIf="content?.intro.link2Label && content?.intro.link2Url"
      href="{{content?.intro.link2Url}}"
      class="intro-link _button--medium _button--outline"
    >{{content?.intro.link2Label}}</a>
  </div>
</section>

<!-- Lists -->
<ul
  *ngFor="let list of content?.lists; let i = index"
  class="list">
  <li
    *ngFor="let item of list"
    class="list-li">
    <a
      *ngIf="item.link"
      href="{{item.link}}"
      [attr.target]="item.target || null"
    >{{item.label}}</a>
    <span
      *ngIf="!item.link"
    >{{item.label}}</span>
  </li>
  <ng-container *ngIf="i === content?.lists.length - 1">
    <li class="list-li" *ngIf="cookiesInited">
      <button onclick="CookieFirst.openPanel();">cookies</button>
    </li>
    <li *ngIf="!copyrightLong">
      <span>© {{dateNow | date:'Y'}} {{siteTitle}}</span>
    </li>
  </ng-container>
</ul>

<!-- <li [class.language--selected]="localeId === 'nl-be'"><a href="/platform{{currentURLForSwitching}}" i18n>Nederlands</a></li>
<li [class.language--selected]="localeId === 'en'"><a href="/platform/en{{currentURLForSwitching}}" i18n>English</a></li> -->

<!-- Logos -->
<ul
  *ngIf="content?.logos?.length"
  class="logos">
  <li
    *ngFor="let logo of content?.logos"
    class="logo">
    <a
      *ngIf="logo.link"
      class="logo-link"
      href="{{logo.link}}"
      target="_blank"
      rel="noopener"
    ><ng-template *ngTemplateOutlet="logoImage"></ng-template></a>
    <div
      *ngIf="!logo.link"
      class="logo-unlink"
    ><ng-template *ngTemplateOutlet="logoImage"></ng-template></div>
    <ng-template #logoImage>
      <img
        class="logo-image"
        alt="{{logo.label}}"
        title="{{logo.label}}"
        loading="lazy"
        src="{{logo.image}}">
    </ng-template>
  </li>
</ul>

<p *ngIf="copyrightLong" class="copyrightLong">© {{dateNow | date:'Y'}} {{siteTitle}}{{copyrightLong}}</p>