import { Injectable } from '@angular/core';
import { Keys } from '../statics/keys';

@Injectable({
  providedIn: 'root'
})
export class KeyHelper {

  /** Gets the value of a key of a certain type */
  getKeyValue(key: string, type: string): string {
    const keys = Keys[type];
    return keys ? keys[key] || key : key;
  }
}
