import { Component, ElementRef, EventEmitter, Input, AfterViewInit, Output, ViewChild } from '@angular/core';
import { HumanError } from '../../../models/human-error/human-error';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements AfterViewInit {

  @ViewChild('closeButton') closeButton: ElementRef<any>;
  @Input() humanError: HumanError;
  @Output() closed: EventEmitter<any> = new EventEmitter();

  ngAfterViewInit() {
    this.closeButton.nativeElement.focus();
  }

  close() {
    this.closed.emit();
  }
}
