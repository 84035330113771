import { NgModule } from '@angular/core';
import { ArtistPublicContentsPipe } from './artist-public-contents.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
import { UserInvitedPipe } from './user-invited.pipe';
import { ArtistShowsPipe } from './artist-shows.pipe';
import { KeyPipe } from './key.pipe';
import { DurationPipe } from './duration.pipe';
import { CallSubmissionsPipe } from './call-submissions.pipe';
import { ImagePipe } from './image.pipe';
import { ListPipe } from './list.pipe';
import { StripHTMLPipe } from './strip-html.pipe';
import { OrderByPipe } from './orderBy.pipe';
import { MarkPipe } from './mark.pipe';
import { ReplaceNbspPipe } from './replace-nbsp.pipe';
import { AgePipe } from './age.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  declarations: [
    ArtistPublicContentsPipe,
    RelativeTimePipe,
    UserInvitedPipe,
    ArtistShowsPipe,
    KeyPipe,
    DurationPipe,
    CallSubmissionsPipe,
    ImagePipe,
    ListPipe,
    SafeHtmlPipe,
    StripHTMLPipe,
    OrderByPipe,
    MarkPipe,
    ReplaceNbspPipe,
    AgePipe
  ],
  exports: [
    ArtistPublicContentsPipe,
    RelativeTimePipe,
    UserInvitedPipe,
    ArtistShowsPipe,
    KeyPipe,
    DurationPipe,
    CallSubmissionsPipe,
    ImagePipe,
    ListPipe,
    SafeHtmlPipe,
    StripHTMLPipe,
    OrderByPipe,
    MarkPipe,
    ReplaceNbspPipe,
    AgePipe
  ],
  providers: [
    CallSubmissionsPipe
  ]
})
export class PipesModule {}
