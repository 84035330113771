import { Pipe, PipeTransform } from '@angular/core';
import { UserHelper } from '../helpers/user.helper';
import {
  CallSubmission,
  CallSubmissionComplies,
  CallSubmissionStateType,
} from '../models/call-submission/call-submission';
import { EntityList } from '../models/entity-list/entity-list';
import { OrganisationCallCallSubmissionsSortUI } from '../models/organisation/organisation-call';
import { AuthService } from '../services/auth/auth.service';
@Pipe({
  name: 'callSubmissions',
})
export class CallSubmissionsPipe implements PipeTransform {
  constructor(
    private userHelper: UserHelper,
    private authService: AuthService
  ) {}

  transform(
    callSubmissions: EntityList<CallSubmission>,
    state: CallSubmissionStateType = 'SUBMITTED',
    sort: OrganisationCallCallSubmissionsSortUI = 'dateDesc',
    complies: CallSubmissionComplies = {
      audience: false,
      genre: false,
      location: false,
    }
  ): EntityList<CallSubmission> {
    // Placeholder
    const callSubmissionsTransformed: EntityList<CallSubmission> = {
      items: [],
      total: 0,
    };

    // Whether the current user can admin
    const canAdmin = this.userHelper.isCurrentUserAdmin();

    // Filter by state
    callSubmissionsTransformed.items =
      callSubmissions?.items.filter((callSubmission) => {
        if (state === 'SUBMITTED') {
          return canAdmin ? callSubmission.dateSubmitted : true;
        } else if (state === 'DRAFT') {
          return canAdmin && !callSubmission.dateSubmitted;
        } else {
          return false;
        }
      }) || [];

    // Filter by complies
    callSubmissionsTransformed.items = callSubmissionsTransformed.items.filter(
      (callSubmission) => {
        if (complies.genre && !callSubmission.complies?.genre) {
          return false;
        } else if (complies.location && !callSubmission.complies?.location) {
          return false;
        } else if (complies.audience && !callSubmission.complies?.audience) {
          return false;
        } else {
          return true;
        }
      }
    );

    // Sort
    callSubmissionsTransformed.items.sort((a, b) => {
      if (sort === 'nameAsc') {
        return a.artistInfo.name.localeCompare(b.artistInfo.name);
      } else if (sort === 'nameDesc') {
        return b.artistInfo.name.localeCompare(a.artistInfo.name);
      } else if (sort === 'dateAsc') {
        return (
          new Date(a.dateSubmitted).getTime() -
          new Date(b.dateSubmitted).getTime()
        );
      } else if (sort === 'dateDesc') {
        return (
          new Date(b.dateSubmitted).getTime() -
          new Date(a.dateSubmitted).getTime()
        );
      } else if (['allRatingsAsc', 'allRatingsDesc'].includes(sort)) {
        const aRating = a.rating === 0 ? 0 : a.rating || -1;
        const bRating = b.rating === 0 ? 0 : b.rating || -1;
        if (sort === 'allRatingsAsc') {
          return (
            aRating - bRating ||
            a.artistInfo.name.localeCompare(b.artistInfo.name)
          );
        } else if (sort === 'allRatingsDesc') {
          return (
            bRating - aRating ||
            a.artistInfo.name.localeCompare(b.artistInfo.name)
          );
        }
      } else if (['myRatingAsc', 'myRatingDesc'].includes(sort)) {
        const currentUser = this.authService.currentUser.value;
        const aResponse = a.responses?.find(
          (response) => response.user.uuid === currentUser.uuid
        ) || { rating: -1 };
        const bResponse = b.responses?.find(
          (response) => response.user.uuid === currentUser.uuid
        ) || { rating: -1 };
        if (sort === 'myRatingAsc') {
          return (
            aResponse.rating - bResponse.rating ||
            a.artistInfo.name.localeCompare(b.artistInfo.name)
          );
        } else if (sort === 'myRatingDesc') {
          return (
            bResponse.rating - aResponse.rating ||
            a.artistInfo.name.localeCompare(b.artistInfo.name)
          );
        }
      }
    });

    // Update total
    callSubmissionsTransformed.total = callSubmissionsTransformed.items.length;

    // Return
    return callSubmissionsTransformed;
  }
}
