import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContentFooterList } from '../../models/content/content';
import { ContentService } from '../../services/content/content.service';
import { CookieService } from '../../services/cookie/cookie.service';
import { PlayerService } from '../../services/player/player.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appFooterMinimal]',
  templateUrl: './footer-minimal.component.html',
  styleUrls: ['./footer-minimal.component.scss'],
})
export class FooterMinimalComponent implements OnInit, OnDestroy {
  @HostBinding('class.footer-minimal--playerOpen') playerOpen = false;

  public dateNow = new Date();
  public siteTitle: string;
  public list: ContentFooterList;
  public cookiesInited = false;
  public copyrightLong: string;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private playerService: PlayerService,
    private contentService: ContentService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    // Get the content
    this.siteTitle = this.contentService.getContentByPath('settings.siteTitle');
    this.list = this.contentService.getContentByPath('footerMinimal');
    this.copyrightLong = this.contentService.getContentByPath(
      'settings.copyrightLong'
    );

    // Subscribe to whether the player is open
    this.playerService.open
      .pipe(takeUntil(this.destroyed$))
      .subscribe((open) => (this.playerOpen = open));

    // Subscribe to whether the cookies are inited
    this.cookieService.inited
      .pipe(takeUntil(this.destroyed$))
      .subscribe((inited) => (this.cookiesInited = inited));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
