import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'image',
})
export class ImagePipe implements PipeTransform {
  constructor() {}

  transform(url: string, width: number, height: number, max?: boolean): string {
    return url.includes('.imgix.net')
      ? this.getImgixURL(url, width, height, max)
      : this.getImageKitURL(url, width, height, max);
  }

  private getImgixURL(
    url: string,
    width: number,
    height: number,
    max?: boolean
  ) {
    // Params
    let params = [];

    // If max, otherwise crop
    if (max) {
      params = ['fit=max'];
    } else {
      params = ['fit=crop', 'crop=edges'];
    }

    // Width
    if (width) {
      params.push(`w=${width}`);
    }

    // Height
    if (height) {
      params.push(`h=${height}`);
    }

    // Auto
    // Compress when JPG, otherwise also convert to WebP
    const auto = url.toLowerCase().includes('.jpg')
      ? 'compress'
      : 'compress,format';
    params.push(`auto=${auto}`);

    return `${url}?${params.join('&')}`;
  }

  private getImageKitURL(
    url: string,
    width: number,
    height: number,
    max?: boolean
  ) {
    // Params
    let params = [];

    // If max, otherwise crop
    if (max) {
      params = ['c-at_max'];
    } else {
      params = ['c-maintain_ratio', 'fo-auto'];
    }

    // Width
    if (width) {
      params.push(`w-${width}`);
    }

    // Height
    if (height) {
      params.push(`h-${height}`);
    }

    return `${url}?tr=${params.join(',')}`;
  }
}
