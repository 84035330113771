<ul class="list">
  <li
    *ngFor="let item of list"
    class="list-item">
    <a
      *ngIf="item.link"
      href="{{item.link}}"
    >{{item.label}}</a>
    <span
      *ngIf="!item.link"
    >{{item.label}}</span>
  </li>
  <li class="list-item" *ngIf="cookiesInited">
    <button onclick="CookieFirst.openPanel();" i18n>cookies</button>
  </li>
  <li class="list-item--copyright" *ngIf="!copyrightLong">
    <span>© {{dateNow | date:'Y'}}</span>
  </li>
</ul>

<p class="copyrightLong" *ngIf="copyrightLong">© {{dateNow | date:'Y'}} {{siteTitle}}{{copyrightLong}}</p>