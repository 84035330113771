import { Injectable } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import { map, catchError, of } from 'rxjs';
import { Artist } from '../models/artist/artist';
import { Organisation } from '../models/organisation/organisation';
import { ProfileService } from '../services/profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileCanMatch  {

  constructor(private profileService: ProfileService) {}

  canMatch(route: Route, segments: UrlSegment[]) {
    const slug = segments[0].path;
    const typeRequested = route.data.profileType.toLowerCase();
    return this.profileService.getProfile(slug).pipe(
      map((profile: Organisation | Artist) =>
        profile.type.toLowerCase() === typeRequested),
      catchError(() => of(false)));
  }
}
