import { Observable } from 'rxjs';

export class Cache {

  cache: Observable<any>;

  private container: {[key: string]: any};
  private timer: any;
  private key: string;

  constructor(
    container: {[key: string]: any},
    key: string,
    cache: Observable<any>,
    time: number = 30000
  ) {

    // Set properties
    this.container = container;
    this.key = key;
    this.cache = cache;
    if (time) {
      this.timer = setTimeout(() => {
        this.clear();
      }, time);
    }
  }

  /** Clears the timeOut and deletes the cache item from the container */
  public clear() {
    if (this.timer) { clearTimeout(this.timer); }
    delete this.container[this.key];
  }
}
