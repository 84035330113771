/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */

import { OrganisationCallFilter } from '../models/organisation/organisation-call';
import { ReportType } from '../models/report/report';

export class Keys {
  /** Gender */
  public static readonly GENDER = {
    M: $localize`Man`,
    V: $localize`Vrouw`,
    X: $localize`Onbepaald`,
  };

  /** Instrument */
  public static readonly INSTRUMENT = {
    bass: $localize`Bas`,
    dj: $localize`DJ`,
    drum: $localize`Drum`,
    electronica: $localize`Elektronica`,
    guitar: $localize`Gitaar`,
    keys: $localize`Toetsen`,
    percussion: $localize`Percussie`,
    strings: $localize`Strijker`,
    vocals: $localize`Stem`,
    horns: $localize`Blazer`,
    other: $localize`Andere`,
  };

  /** Invited User State */
  public static readonly INVITED_USER_STATE = {
    PENDING: $localize`In afwachting`,
    ACCEPTED: $localize`Geaccepteerd`,
    DECLINED: $localize`Geweigerd`,
  };

  /** Organisation Type */
  public static readonly ORGANISATION_TYPE = {
    alternatieve_muziekeducatie: $localize`Alternatieve muziekeducatie`,
    beheersvennootschap: $localize`Beheersvennootschap`,
    boekingskantoor: $localize`Boekingskantoor`,
    booking: $localize`Booking`,
    cafe: $localize`Café`,
    club: $localize`Club`,
    concertzaal: $localize`Concertzaal`,
    cultuurcentrum: $localize`Cultuurcentrum`,
    deeltijds_kunstonderwijs: $localize`Deeltijds kunstonderwijs`,
    distributeur: $localize`Distributeur`,
    event: $localize`Event`,
    festival: $localize`Festival`,
    gedrukte_media: $localize`Gedrukte media`,
    hogere_opleiding: $localize`Hogere opleiding`,
    jeugdhuis: $localize`Jeugdhuis`,
    jongerencentrum: $localize`Jongerencentrum`,
    kunstonderwijs: $localize`Kunstonderwijs`,
    label: $localize`Label`,
    management: $localize`Management`,
    media_pers: $localize`Media / pers`,
    muziekschool: $localize`Muziekschool`,
    muziekwedstrijd: $localize`Muziekwedstrijd`,
    onafhankelijke_organisator: $localize`Onafhankelijke organisator`,
    ondersteunde_organisatie: $localize`Ondersteunende organisatie`,
    online_media: $localize`Online media`,
    overheid: $localize`Overheid`,
    overig: $localize`Overig`,
    plugging_promo: $localize`Plugging & promo`,
    popkoepel: $localize`Popkoepel`,
    publisher: $localize`Publisher`,
    radio_tv: $localize`Radio / Tv`,
    repetitieruimte: $localize`Repetitieruimte`,
    retail: $localize`Retail`,
    sbk: $localize`SBK`,
    studio: $localize`Studio`,
    talentontwikkeling: $localize`Talentontwikkeling`,
    technische_service: $localize`Technische service`,
    technische_services: $localize`Technische services`,
  };

  /** Call Compensation Type */
  public static readonly CALL_COMPENSATION_TYPE = {
    FIXED: $localize`Vast`,
    NEGOTIATED: $localize`Onderhandeld`,
  };

  /** Call State */
  public static readonly CALL_STATE = {
    DRAFT: $localize`Klad`,
    SUBMITTED: $localize`Ingediend`,
    REJECTED: $localize`Verworpen`,
    APPROVED: $localize`Goedgekeurd`,
    CANCELLED: $localize`Geannuleerd`,
  };

  /** Call Sort */
  public static readonly CALL_SORT = {
    portal: $localize`Nieuw`,
    datePublished: $localize`Nieuw`,
    datePublishedAlt: $localize`Gepland`,
    dateDeadline: $localize`Deadline nabij`,
    dateDeadlineAlt: $localize`Deadline`,
    createdDate: $localize`Gemaakt`,
    createdDateAlt: $localize`Gemaakt`,
    modifiedDate: $localize`Bewerkt`,
    modifiedDateAlt: $localize`Bewerkt`,
    dateAnnouncement: $localize`W.A.D.`,
    callSubmissionCount: $localize`Inzendingen`,
  };

  /** Call UI Filter */
  public static readonly CALL_FILTER:
    | { [key in OrganisationCallFilter]: string }
    | { ALL: string } = {
    ALL: $localize`Alle`,
    DRAFT: $localize`Klad`,
    SUBMITTED: $localize`Ingediend`,
    REJECTED: $localize`Verworpen`,
    SCHEDULED: $localize`Gepland`,
    RUNNING: $localize`Actief`,
    WINNER: $localize`Winnaar`,
    PENDING: $localize`Pending`,
    OVERDUE: $localize`Overdue`,
    CANCELLED: $localize`Geannuleerd`,
  };

  /** Call Category */
  public static readonly CALL_CATEGORY = {
    performance: $localize`Optreden`,
    media: $localize`Media`,
    feedback: $localize`Feedback`,
    studio: $localize`Studio`,
    image: $localize`Beeld`,
    collaboration: $localize`Samenwerking`,
    residency: $localize`Residentie`,
    production: $localize`Productie`,
    songwriting: $localize`Songwriting`,
    cover: $localize`Cover`,
    remix: $localize`Remix`,
    finance: $localize`Fonds of financiën`,
    talent: $localize`Talent support`,
  };

  /** Artist Type */
  public static readonly ARTIST_TYPE = {
    band: $localize`Band`,
    dj: $localize`DJ`,
    producer: $localize`Producer`,
    solo: $localize`Solo`,
  };

  /** Artist Type Multiple */
  public static readonly ARTIST_TYPE_MULTIPLE = {
    band: $localize`Bands`,
    dj: $localize`DJ’s`,
    producer: $localize`Producers`,
    solo: $localize`Soloartiesten`,
  };

  /** Artist Sort */
  public static readonly ARTIST_SORT = {
    name: $localize`Alfabetisch`,
    createdDate: $localize`Nieuw`,
    modifiedDate: $localize`Laatst bewerkt`,
    winCount: $localize`Kansen gewonnen`,
    winRecent: $localize`Recente winnaars`,
  };

  /** Artist Privacy */
  public static readonly ARTIST_PRIVACY = {
    private: $localize`Verborgen op je profiel`,
    public: $localize`Publiek op je profiel`,
  };

  /** Report Types */
  public static readonly REPORT_TYPE: { [key in ReportType]: string } = {
    ARTIST: $localize`Artiesten`,
    ARTIST_MEMBER: $localize`Groepsleden`,
    CALL_SUBMISSION: $localize`Inzendingen`,
    ORGANISATION: $localize`Organisaties`,
    ORGANISATION_CALL: $localize`Kansen`,
    USER: $localize`Gebruikers`,
  };

  /** Server Error */
  public static readonly SERVER_ERROR = {
    'entity.not.found': $localize`De entiteit kon niet worden gevonden.`,
    'constraint.violation': $localize`De aanvraag voldeed niet aan de vereisten.`,
    'access.denied.not.a.member': $localize`Je hebt geen toegang, omdat je geen lid bent.`,
    'failed.to.upload.file': $localize`Het uploaden van het bestand is mislukt.`,
    'failed.to.delete.file': $localize`Het verwijderen van het betsand is mislukt.`,
    'image.not.linked.with.artist': $localize`De afbeelding behoort niet tot de artiest.`,
    'link.not.linked.with.artist': $localize`De link behoort niet tot de artiest.`,
    'member.not.linked.with.artist': $localize`Het lid behoort niet tot de artiest.`,
    'artist.content.not.linked.with.artist': $localize`De inhoud behoort niet tot de artiest.`,
    'public.content.not.linked.with.artist': $localize`De publieke inhoud behoort niet tot de artiest.`,
    'show.not.linked.with.artist': $localize`De show behoort niet tot de artiest.`,
    'track.not.linked.with.artist': $localize`De track behoort niet tot de artiest.`,
    'link.not.linked.with.artist.track': $localize`De link behoort niet tot de track.`,
    'timecode.not.linked.with.artist.track': $localize`De timecode behoort niet tot de track.`,
    'video.not.linked.with.artist': $localize`De video behoort niet tot de artiest.`,
    'slug.already.exists': $localize`De URL bestaat al.`,
    'change.email.failed': $localize`Het wijzigen van het e-mailadres is mislukt.`,
    'user.not.found': $localize`De gebruiker kon niet gevonden worden.`,
    'change.password.failed': $localize`Het wijzigen van het wachtwoord is mislukt.`,
    'invalid.password': $localize`Je wachtwoord moet minstens 8 tekens bevatten, waaronder een cijfer, hoofdletter, kleine letter en speciaal teken (=+-@?!, ...).`,
    'code.mismatch': $localize`De codes komen niet overeen.`,
    'confirm.forgot.password.failed': $localize`De bevestiging om je wachtwoord opnieuw in te stellen is mislukt.`,
    'expired.code': $localize`De code is vervallen.`,
    'confirm.sign.up.failed': $localize`De bevestiging van de registratie is mislukt.`,
    'failed.to.send.confirmation.email': $localize`De e-mail met de bevestiging kon niet worden verstuurd.`,
    'resend.confirmation.code.failed': $localize`De bevestigingscode kon niet opnieuw worden verstuurd.`,
    'user.already.confirmed': $localize`De registratie van deze gebruiker is al bevestigd.`,
    'resend.verification.code.failed': $localize`De verificatiecode kon niet opnieuw worden verstuurd.`,
    'reset.password.failed': $localize`Het wachtwoord kon niet opnieuw worden ingesteld.`,
    'sign.up.failed': $localize`De registratie is mislukt.`,
    'email.already.exists': $localize`Het e-mailadres is al in gebruik.`,
    'update.user.attributes.failed': $localize`Het wijzigen van de gegevens van de gebruiker is mislukt.`,
    'verify.email.failed': $localize`Het verifiëren van het e-mailadres is mislukt.`,
    'answer.already.exists.for.question': $localize`Er bestaat al een antwoord op de vraag.`,
    'cannot.update.call.submission.already.submitted': $localize`Het wijzigen van de inzending is mislukt, omdat het al is ingezonden.`,
    'organisation.call.not.approved': $localize`De kans is niet goedgekeurd.`,
    'organisation.call.not.yet.published': $localize`De kans is nog niet gepubliceerd.`,
    'organisation.call.passed.deadline': $localize`De deadline van de kans is verstreken.`,
    'question.not.linked.with.organisation.call': $localize`De vraag behoort niet tot de kans.`,
    'answer.not.linked.with.call.submission': $localize`Het antwoord behoort niet tot de inzending.`,
    'artist.content.not.linked.with.call.submission': $localize`De inhoud van de artiest behoort niet tot de inzending.`,
    'track.type.not.allowed': $localize`Het type track is niet toegestaan.`,
    'response.not.linked.with.call.submission': $localize`Het antwoord van het jurylid behoort niet tot de inzending.`,
    'feedback.already.published': $localize`De feedback is al gepubliceerd.`,
    'feedback.not.linked.with.call.submission': $localize`De feedback behoort niet tot de inzending.`,
    'response.already.exists.for.call.submission': $localize`Er bestaat al een antwoord van het jurylid voor de inzending.`,
    'artist.already.submitted.to.call': $localize`De artiest heeft al een inzending voor de kans.`,
    'cannot.remove.call.submission.announced.as.winner': $localize`De inzending kan niet worden verwijderd, omdat de artiest aangekondigd is als winnaar.`,
    'template.already.exists': $localize`De template bestaat al.`,
    'call.not.linked.with.organisation': $localize`De kans behoort niet tot de organisatie.`,
    'announcement.not.linked.with.organisation.call': $localize`De aankondiging behoort niet tot de kans.`,
    'call.submission.not.linked.with.organisation.call': $localize`De inzending behoort niet tot de kans.`,
    'cannot.update.call.not.in.state.draft': $localize`Het wijzigen van de kans is mislukt, omdat het niet de status klad heeft.`,
    'category.not.linked.with.organisation.call': $localize`De categorie behoort niet tot de kans.`,
    'genre.not.linked.with.organisation.call': $localize`Het genre behoort niet tot de kans.`,
    'missing.required.role': $localize`De gebruiker heeft niet de geschikte rol.`,
    'reviewer.not.linked.with.organisation.call': $localize`De beheerder behoort niet tot de kans.`,
    'artist.type.already.linked.with.organisation.call': $localize`Het artiesttype behoort al tot de kans.`,
    'target.audience.not.linked.with.organisation.call': $localize`De doelgroep behoort niet tot de kans.`,
    'location.already.linked.with.organisation.call': $localize`De locatie behoort al tot de kans.`,
    'target.location.not.linked.with.organisation.call': $localize`De locatie behoort niet tot de kans.`,
    'cannot.accept.invitation.not.pending': $localize`De uitnodiging kan niet worden geaccepteerd, omdat het niet in afwachting is.`,
    'cannot.decline.invitation.not.pending': $localize`De uitnodiging kan niet worden geweigerd, omdat het niet in afwachting is.`,
    'cannot.reinvite.not.declined': $localize`De uitnodiging kan niet opnieuw worden verstuurd, omdat het niet geweigerd is.`,
    'user.already.added.as.member': $localize`De gebruiker is al lid.`,
    'cannot.approve.call.not.in.state.submitted': $localize`De kans kan niet worden goedgekeurd, omdat ze niet ingezonden is.`,
    'access.denied': $localize`De toegang is geweigerd.`,
    'cannot.cancel.call.not.in.state.approved': $localize`De kans kan niet worden geannuleerd, omdat ze niet goedgekeurd is.`,
    'cannot.decline.call.not.in.state.submitted': $localize`De kans kan niet worden afgekeurd, omdat ze niet ingezonden is.`,
    'cannot.remove.call.not.in.state.draft': $localize`De kans kan niet worden verwijderd, omdat ze niet de status klad heeft.`,
    'cannot.reject.call.not.in.state.submitted': $localize`De kans kan niet worden verworpen, omdat ze niet ingezonden is.`,
    'cannot.submit.call.not.in.state.draft': $localize`De kans kan niet worden ingezonden, omdat ze niet de status klad heeft.`,
    'role.already.assigned.to.user': $localize`De rol is al toegewezen aan de gebruiker.`,
    'unassignable.role': $localize`De rol kan niet worden toegewezen.`,
  };

  /** COGNITO AWS */
  public static readonly COGNITO_AWS_ERROR = {
    'Incorrect username or password.': $localize`Je e-mail of wachtwoord is fout. Wachtwoorden zijn hoofdlettergevoelig. Controleer of CAPS-lock niet is ingeschakeld.`,
    'User is not confirmed.': $localize`De gebruiker is niet geverifieerd.`,
  };
}
