/**
 * Copies the element’s height to a CSS variable of choice. Useful
 * for CSS calculations that need to take into account this height,
 * such as sticking an element to the bottom of the screen when
 * its bottom has reached it.
 */

import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, Input, OnDestroy, PLATFORM_ID } from '@angular/core';

@Directive({
  selector: '[appHeightVariable]'
})
export class HeightVariableDirective implements AfterViewInit, OnDestroy {

  @Input() appHeightVariable: {
    name?: string; // The name of the variable
    setToRoot?: boolean; // Whether the CSS variable should be placed on the root element
  };

  private nativeElement: HTMLElement;
  private resizeObserver: ResizeObserver;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private elementRef: ElementRef) {

    if (isPlatformBrowser(this.platformId)) {

      // Bind the native element
      this.nativeElement = (this.elementRef.nativeElement as HTMLElement);

      // Initialize the resize observer
      this.resizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0];
        if (entry) {
          const name = `--${this.appHeightVariable?.name || 'height'}`;
          const height =`${entry.target.getBoundingClientRect().height}px`;
          const element = this.appHeightVariable?.setToRoot ? document.documentElement : this.nativeElement;
          element.style.setProperty(name, height);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.resizeObserver.observe(this.nativeElement);
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.resizeObserver.disconnect();
      const name = `--${this.appHeightVariable?.name || 'height'}`;
      document.documentElement.style.removeProperty(name);
    }
  }
}
