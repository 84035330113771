/* eslint-disable @typescript-eslint/naming-convention */
// Dependencies
import * as jwt_decode from 'jwt-decode';

// Core
import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, forkJoin, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

// Config
import { ConfigService } from '../config/config.service';

// AWS Cognito
import {
  CognitoUserPool,
  CognitoUser,
  CognitoRefreshToken,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken
} from 'amazon-cognito-identity-js';

// Application
import { UserService } from '../user/user.service';
import { ChangeEmailRequest } from '../../models/auth/change-email-request';
import { ChangePasswordRequest } from '../../models/auth/change-password-request';
import { ConfirmForgotPasswordRequest } from '../../models/auth/confirm-forgot-password-request';
import { ConfirmSignUpRequest } from '../../models/auth/confirm-sign-up-request';
import { ResendCodeResponse } from '../../models/auth/resend-code-response';
import { ResendConfirmationCodeRequest } from '../../models/auth/resend-confirmation-code-request';
import { ResetPasswordRequest } from '../../models/auth/reset-password-request';
import { SignUpRequest } from '../../models/auth/sign-up-request';
import { UpdateUserAttributesRequest } from '../../models/auth/update-user-attributes-request';
import { User } from '../../models/user/user';
import { VerifyEmailRequest } from '../../models/auth/verify-email-request';
import { PrepareCurrentUserResponse } from '../../models/auth/prepare-current-user-response';
import { RefreshSessionResponse } from '../../models/auth/refresh-session-response';
import { CacheService } from '../cache/cache.service';
import { CustomURLEncoder } from '../../helpers/utils';
import { isPlatformBrowser } from '@angular/common';
import { HumanErrorService } from '../human-error/human-error.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public signedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public currentUser: BehaviorSubject<User> = new BehaviorSubject(null);
  private refreshSessionTimeout: any;

  private cognitoUserPool: CognitoUserPool;

  constructor(
    @Inject(LOCALE_ID) public localeId: string,
    @Inject(PLATFORM_ID) private platformId: string,
    private cacheService: CacheService,
    private config: ConfigService,
    private http: HttpClient,
    private userService: UserService,
    private humanErrorService: HumanErrorService,
    private router: Router) {}

  getCognitoUserPool(): CognitoUserPool {
    if (!this.cognitoUserPool) {
      this.cognitoUserPool = new CognitoUserPool(this.config.get().cognitoPool);
    }
    return this.cognitoUserPool;
  }

  /** Signs up a new user */
  signup(request: SignUpRequest): Observable<any> {
    return this.http.put(`${this.config.get().backendUrl}/auth/signUp`, request);
  }

  /** Signs out the current user */
  signout(): void {

    // If this is the browser
    if (isPlatformBrowser(this.platformId)) {

      // Clear the interval that refreshes the session
      this.clearRefreshSessionTimeout();

      // Remove all relevant localStorage items
      const lastAuthUser = this.getLastAuthUser();
      // eslint-disable-next-line max-len
      window.localStorage.removeItem(`CognitoIdentityServiceProvider.${this.config.get().cognitoPool.ClientId}.${lastAuthUser}.accessToken`);
      // eslint-disable-next-line max-len
      window.localStorage.removeItem(`CognitoIdentityServiceProvider.${this.config.get().cognitoPool.ClientId}.${lastAuthUser}.refreshToken`);
      window.localStorage.removeItem(`CognitoIdentityServiceProvider.${this.config.get().cognitoPool.ClientId}.${lastAuthUser}.idToken`);
      window.localStorage.removeItem(`CognitoIdentityServiceProvider.${this.config.get().cognitoPool.ClientId}.${lastAuthUser}.clockDrift`);
      window.localStorage.removeItem(`CognitoIdentityServiceProvider.${this.config.get().cognitoPool.ClientId}.LastAuthUser`);

      // Unset logged in
      this.signedIn.next(false);

      // Unset the current user
      this.currentUser.next(null);

      // Clear the entire cache
      this.cacheService.clearAll();
    }
  }

  /*
   * Refreshes the access and id tokens for the currently signed
   * in user, using the current refresh token.
   */
  refreshSession(): Observable<RefreshSessionResponse> {
    const result$: BehaviorSubject<RefreshSessionResponse> = new BehaviorSubject('pending');
    const result = result$.asObservable();
    const user = jwt_decode(this.getIdToken());
    if (user) {
      const cognitoUser = new CognitoUser({
        Username: user.email,
        Pool: this.getCognitoUserPool()
      });
      cognitoUser.refreshSession(new CognitoRefreshToken({RefreshToken: this.getRefreshToken()}), (error, session) => {
        if (error) {
          console.error(`ERROR: ${error.message}`);
          result$.next('error');
        } else {
          this.prepareCurrentUser().pipe(take(2)).subscribe((prepareCurrentUserResponse: PrepareCurrentUserResponse) => {
            if (prepareCurrentUserResponse === 'done') {
              result$.next('done');
            } else if (prepareCurrentUserResponse === 'error') {
              this.signout();
              result$.next('error');
            }
          });
        }
      });
    }
    return result;
  }

  /** Sets a timeout until a specific expiration time */
  setRefreshSessionTimeout(expiration: number): void {
    const milisecondsUntilExpiration = (expiration * 1000) - Math.floor(Date.now());
    this.clearRefreshSessionTimeout();
    this.refreshSessionTimeout = setTimeout(() => {
      this.refreshSession();
    }, milisecondsUntilExpiration);
  }

  /** Clears the timeout which refreshes the session */
  clearRefreshSessionTimeout(): void {
    if (this.refreshSessionTimeout) {
      clearInterval(this.refreshSessionTimeout);
    }
  }

  /* Exchange an authorization code for authorization tokens. */
  fetchTokens(code: string): Observable<any> {
    const body: HttpParams = new HttpParams({encoder: new CustomURLEncoder()})
      .set('grant_type', 'authorization_code')
      .set('client_id', this.config.get().cognitoPool.ClientId)
      .set('code', code)
      .set('redirect_uri', this.config.get().redirectUri);
    return this.http.post(`${this.config.get().authUrl}/oauth2/token`, body);
  }

  /** Sets the current user and its roles, the signed in state and starts a timer to refresh the JWT tokens in an hour */
  prepareCurrentUser(): Observable<PrepareCurrentUserResponse> {
    const result$: BehaviorSubject<PrepareCurrentUserResponse> = new BehaviorSubject('pending');
    const result = result$.asObservable();

    // Set the current user
    const idToken = this.getIdToken();
    if (idToken) {

      // Decode the token to get the user
      const user = jwt_decode(idToken);

      // Get the platform v2 user id
      const v2Id = user['custom:v2id'];

      // Optionally make a request to complete the migration on the server
      const optionalCompleteMigrationRequest = v2Id && !v2Id.includes('migrated_') ?
        this.userService
          .completeMigration(user['custom:v2id'], user.email, user.given_name)
          .pipe(catchError(() => of('error'))) :
        of(null);
      optionalCompleteMigrationRequest.subscribe(optionalResult => {

        if (optionalResult === 'error') {

          // Show error
          this.humanErrorService.setHumanError({
            message: $localize`Er ging iets mis. Gelieve opnieuw in te loggen.`
          });

          // Sign out
          this.signout();

          // Go to signin, with a slight delay to be sure
          setTimeout(() => {
            this.router.navigateByUrl('/auth/signin');
          }, 1500);

          // Unset signedin
          this.signedIn.next(false);

          // Change to result to done
          result$.next('done');

        } else {

          // Assign the crucial requests
          const crucialRequests = [
            this.userService
              .getUserRoles(user['cognito:username'])
              .pipe(catchError(() => of(null))),
            this.userService
              .getUserInvites(user.sub)
              .pipe(catchError(() => of(null))),
            this.userService
              .getUserOrganisationCalls(user.sub)
              .pipe(catchError(() => of(null)))];

          // Perform the crucial requests
          forkJoin(crucialRequests)
          .subscribe((results: any[]) => {

            // Get the roles, invites and accepted organisation calls
            const roles = results[0];
            const invites = results[1];
            const acceptedOrganisationCalls = results[2];

            // Assign the data to the current user
            this.currentUser.next({
              uuid: user.sub,
              username: user['cognito:username'],
              familyName: user.family_name,
              givenName: user.given_name,
              email: user.email,
              verified: user.email_verified,
              skippedFinalize: user['custom:skipped_finalize'] === 'true' ? true : false,
              completedMigration: user['custom:completed_migration'] === 'true' ? true : false,
              v2Id: user['custom:v2id'],
              image: user.picture,
              roles,
              invites,
              acceptedOrganisationCalls
            });

            // Set logged in
            this.signedIn.next(true);

            // Change to result to done
            result$.next('done');
          });
        }
      });

        // Set a timer to refresh the tokens
        this.setRefreshSessionTimeout(user.exp);
    }

    // If there's no IdToken
    else {

      // Change the result to done
      result$.next('done');
    }

    // Return the observable
    return result;
  }

  confirmSignUp(request: ConfirmSignUpRequest): Observable<string> {
    return this.http.post<string>(`${this.config.get().backendUrl}/auth/confirmSignUp`, request);
  }

  resendConfirmationCode(request: ResendConfirmationCodeRequest): Observable<ResendCodeResponse> {
    request = {
      language: this.localeId,
      ...request
    };
    return this.http.post<ResendCodeResponse>(`${this.config.get().backendUrl}/auth/resendConfirmationCode`, request);
  }

  resetPassword(request: ResetPasswordRequest): Observable<string> {
    request = {
      language: this.localeId,
      ...request
    };
    return this.http.post<string>(`${this.config.get().backendUrl}/auth/resetPassword`, request);
  }

  confirmForgotPassword(request: ConfirmForgotPasswordRequest): Observable<string> {
    return this.http.post<string>(`${this.config.get().backendUrl}/auth/confirmForgotPassword`, request);
  }

  changeEmail(request: ChangeEmailRequest): Observable<string> {
    return this.http.post<string>(`${this.config.get().backendUrl}/auth/changeEmail`, request);
  }

  verifyEmail(request: VerifyEmailRequest): Observable<string> {
    return this.http.post<string>(`${this.config.get().backendUrl}/auth/verifyEmail`, request);
  }

  resendVerificationCode(): Observable<ResendCodeResponse> {
    return this.http.post<ResendCodeResponse>(`${this.config.get().backendUrl}/auth/resendVerificationCode`, null);
  }

  changePassword(request: ChangePasswordRequest): Observable<string> {
    return this.http.post<string>(`${this.config.get().backendUrl}/auth/changePassword`, request);
  }

  updateUserAttributes(request: UpdateUserAttributesRequest): Observable<string> {
    return this.http.post<string>(`${this.config.get().backendUrl}/auth/updateUserAttributes`, request);
  }

  setTokens(tokens: any): void {
    const authenticationResult = {
      AccessToken: tokens.access_token,
      ExpiresIn: tokens.expires_in,
      IdToken: tokens.id_token,
      RefreshToken: tokens.refresh_token,
      TokenType: tokens.token_type,
    };
    const user = jwt_decode(tokens.id_token);
    const cognitoUser = new CognitoUser({
      Username: user.sub,
      Pool: this.getCognitoUserPool()
    });
    cognitoUser.setSignInUserSession(new CognitoUserSession({
      IdToken: new CognitoIdToken(authenticationResult),
      AccessToken: new CognitoAccessToken(authenticationResult),
      RefreshToken: new CognitoRefreshToken(authenticationResult)
    }));
  }

  /** Returns the uuid of the last auth user */
  getLastAuthUser(): string {
    return window.localStorage.getItem(`CognitoIdentityServiceProvider.${this.config.get().cognitoPool.ClientId}.LastAuthUser`);
  }

  getAccessToken(): string {
    return window.localStorage.getItem(
      `CognitoIdentityServiceProvider.${this.config.get().cognitoPool.ClientId}.${this.getLastAuthUser()}.accessToken`);
  }

  isAccessTokenExpired(): boolean {
    const accessToken = jwt_decode(this.getAccessToken());
    const dateNowUnix = Math.floor(Date.now() / 1000);
    return accessToken ? accessToken.exp < dateNowUnix : true;
  }

  getRefreshToken(): string {
    return window.localStorage.getItem(
      `CognitoIdentityServiceProvider.${this.config.get().cognitoPool.ClientId}.${this.getLastAuthUser()}.refreshToken`);
  }

  getIdToken(): string {
    return window.localStorage.getItem(
      `CognitoIdentityServiceProvider.${this.config.get().cognitoPool.ClientId}.${this.getLastAuthUser()}.idToken`);
  }
}
