import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  HostBinding,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  TemplateRef,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Event as RouterEvent,
} from '@angular/router';
import { delay, take } from 'rxjs/operators';
import { AuthService } from './services/auth/auth.service';
import { User } from './models/user/user';
import { UtilsService } from './services/utils/utils.service';
import { UserService } from './services/user/user.service';
import { EntityList } from './models/entity-list/entity-list';
import { UserInvited } from './models/user/user-invited';
import { PlayerService } from './services/player/player.service';
import { Title } from '@angular/platform-browser';
import { ContentService } from './services/content/content.service';
import { ModalService } from './services/modal/modal.service';
import { HumanErrorService } from './services/human-error/human-error.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { HumanError } from './models/human-error/human-error';
import { UserHelper } from './helpers/user.helper';
import { ConfirmService } from './services/confirm/confirm.service';
import { Confirm } from './models/confirm/confirm';
import { Notice } from './models/notice/notice';
import { DateHelper } from './helpers/date.helper';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /**
   * Add a class to the host which hides the server’s content
   * This prevents a flash when the user is logged in, but the
   * server returns non-logged-in content. When the app’s initializes
   * it removes this class and shows the correct content immediately.
   **/
  @HostBinding('class.app-root--server') server = true;

  @ViewChild('migrationContainer', { read: ViewContainerRef })
  migrationContainer: ViewContainerRef;
  @ViewChild('playerContainer', { read: ViewContainerRef })
  playerContainer: ViewContainerRef;
  @ViewChild('humanServerErrorModalTemplate')
  humanServerErrorModalTemplate: TemplateRef<any>;
  @ViewChild('confirmModalTemplate')
  confirmModalTemplate: TemplateRef<any>;
  @ViewChild('noticeModalTemplate') noticeModalTemplate: TemplateRef<any>;

  public navigating: boolean;
  public currentUser: User;
  public menuOpened = false;
  public pageIsFullScreen = false;
  public pageIsStandalone = false;
  public pageHasMinimalFooter = false;
  public headerUnsticky = false;
  public playerRef: ComponentRef<any>;
  public humanServerError: HumanError;
  public notice: Notice;
  public confirm: Confirm;
  public isContentLoaded = false;

  private navigatingTimeout: any;
  private playerLoading: boolean;
  private playerOpen = false;
  private humanServerErrorModal: OverlayRef;
  private confirmModal: OverlayRef;
  private noticeModal: OverlayRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private utilsService: UtilsService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private playerService: PlayerService,
    private titleService: Title,
    private contentService: ContentService,
    private modalService: ModalService,
    private humanErrorService: HumanErrorService,
    private viewContainerRef: ViewContainerRef,
    private userHelper: UserHelper,
    private confirmService: ConfirmService,
    private dateHelper: DateHelper
  ) {}

  ngOnInit(): void {
    this.server = !isPlatformBrowser(this.platformId);
    this.isContentLoaded = this.contentService.isContentLoaded();

    // If this is the browser
    if (!this.server && this.isContentLoaded) {
      // Subscribe to the user’s object
      this.authService.currentUser.subscribe((user: User) => {
        // ExpressionChangedAfterItHasBeenCheckedError fix
        setTimeout(() => {
          // Set the current user
          this.currentUser = user;

          // If the user is logged in
          if (this.currentUser) {
            // Set the current user's invites if they aren't yet
            if (!this.currentUser.invites) {
              this.setCurrentUserInvites();
            }

            // If the user should finalize, go to /finalize
            else if (this.userHelper.shouldCurrentUserFinalize()) {
              this.utilsService.pageIsStandalone.next(true);
              this.router.navigateByUrl('/finalize');
            }

            // If the user should complete the migration
            else if (this.userHelper.shouldCurrentUserCompleteMigration()) {
              this.createMigration();
            }
          }
        });
      });

      // Subscribe to whether the current page is full screen
      this.utilsService.pageIsFullScreen
        .pipe(delay(0))
        .subscribe(
          (pageIsFullScreen) => (this.pageIsFullScreen = pageIsFullScreen)
        );

      // Subscribe to whether the current page is standalone
      this.utilsService.pageIsStandalone
        .pipe(delay(0))
        .subscribe(
          (pageIsStandalone) => (this.pageIsStandalone = pageIsStandalone)
        );

      // Subscribe to whether the current page has a minimal footer
      this.utilsService.pageHasMinimalFooter
        .pipe(delay(0))
        .subscribe(
          (pageHasMinimalFooter) =>
            (this.pageHasMinimalFooter = pageHasMinimalFooter)
        );

      // Subscribe to the header's stickiness
      this.utilsService.headerSticky
        .pipe(delay(0))
        .subscribe(
          (headerSticky) => (this.headerUnsticky = headerSticky === false)
        );

      // Subscribe to whether the player is open
      this.playerService.open.subscribe((open) => (this.playerOpen = open));

      // Subscribe to whether the player is loading
      this.playerService.playerLoading.subscribe(
        (playerLoading) => (this.playerLoading = playerLoading)
      );

      // Listen for the visibility change event
      document.addEventListener('visibilitychange', () => {
        // If the logged in user has become active again and the access token has expired
        if (
          this.currentUser &&
          !document.hidden &&
          this.authService.isAccessTokenExpired()
        ) {
          // Refresh the session
          this.authService.refreshSession();
        }
      });

      // Subscribe to the human error message
      this.humanErrorService.humanError.subscribe((serverError) => {
        // Set the human readable error
        this.humanServerError = serverError;

        // If there's an error message and a modal isn't already shown
        if (this.humanServerError && !this.humanServerErrorModal) {
          this.humanServerErrorModal = this.modalService.new(
            this.humanServerErrorModalTemplate,
            this.viewContainerRef
          );
        }

        // Otherwise dispose the modal if there's one
        else if (!this.humanServerError && this.humanServerErrorModal) {
          this.humanServerErrorModal?.dispose();
          this.humanServerErrorModal = null;
        }
      });

      // Subscribe to confirm
      this.confirmService.confirmObject.subscribe((confirmObject) => {
        // Set the confirm object
        this.confirm = confirmObject;

        // If there's a confirm object and a modal isn't already shown
        if (this.confirm && !this.confirmModal) {
          this.confirmModal = this.modalService.new(
            this.confirmModalTemplate,
            this.viewContainerRef
          );
        }

        // Otherwise dispose the modal if there's one
        else if (!this.confirm && this.confirmModal) {
          this.confirmModal?.dispose();
          this.confirmModal = null;
        }
      });

      // Show a notice if needed
      this.notice = this.contentService.getContentByPath('notice');
      if (this.notice) {
        const noticePublished =
          !this.notice.dateDepublished ||
          new Date(this.notice.dateDepublished) >
            new Date(this.dateHelper.getNowDateForUser());
        if (
          this.notice &&
          localStorage.getItem('noticeClosed') !== this.notice.uuid &&
          noticePublished
        ) {
          this.openNotice();
        }
      }

      /**
       * Subscribes to the router events.
       * - To determine if the visitor is navigating away from the Organisation or Artist component and thus needing to
       * reset the route configuration so it uses the Profile component again.
       * - To show a progress bar when navigating.
       * - To reset the page title.
       */
      this.router.events.subscribe((event: RouterEvent) => {
        // Start
        if (event instanceof NavigationStart) {
          // Clear the navigating timeout and set it again after 500ms
          if (this.navigatingTimeout) {
            clearTimeout(this.navigatingTimeout);
          }
          this.navigatingTimeout = setTimeout(() => {
            this.navigating = true;
          }, 500);
        }

        // End
        if (event instanceof NavigationEnd) {
          // Reset the title
          this.titleService.setTitle(
            this.contentService.getContentByPath('settings.siteTitle')
          );

          // Stop and clear navigating progress
          this.clearNavigating();
        }

        // Cancel or Error
        if (
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          // Stop and clear navigating progress
          this.clearNavigating();
        }
      });

      // Open the player if a song is played
      this.playerService.current.subscribe((current) => {
        if (current && !window.localStorage.getItem('player-closed')) {
          this.createPlayer();
        }
      });

      // Open the player if the queue changes
      this.playerService.queue.subscribe((queue) => {
        if (queue?.length) {
          this.createPlayer();
        }
      });

      // Open the player if requested
      this.playerService.openRequested.subscribe(() => this.createPlayer());

      // Close the player if requested
      this.playerService.closeRequested.subscribe(() => this.closePlayer());
    }
  }

  /** Sets the current user's invites */
  setCurrentUserInvites() {
    this.userService
      .getUserInvites(this.currentUser.uuid)
      .subscribe((invites: EntityList<UserInvited>) =>
        this.authService.currentUser.next({
          ...this.authService.currentUser.value,
          invites,
        })
      );
  }

  /** Clears the server error */
  clearServerError() {
    this.humanErrorService.setHumanError(null);
  }

  closePlayer() {
    this.playerRef.destroy();
    this.playerRef = null;
    this.playerLoading = false;
    this.playerService.closePlayer();
    this.playerService.pause();
  }

  async createPlayer() {
    if (!this.playerRef && !this.playerLoading && !this.playerOpen) {
      // Set loading
      this.playerService.playerLoading.next(true);

      // Lazy load the component
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { PlayerComponent } = await import(
        './components/player/player.component'
      );

      // Create the component's reference
      this.playerRef = this.playerContainer.createComponent(PlayerComponent);

      // Unset loading
      this.playerService.playerLoading.next(false);

      // Open
      this.playerService.openPlayer();

      // Close the player when requested
      this.playerRef.instance.closed.pipe(take(1)).subscribe(() => {
        this.closePlayer();
      });
    }
  }

  /** Creates the migration component */
  async createMigration() {
    // Lazy load the component
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { MigrationComponent } = await import(
      './components/migration/migration.component'
    );

    // Create the component's reference
    const { instance } =
      this.migrationContainer.createComponent(MigrationComponent);

    // Clear the form container after the modal has closed
    instance.modalClosed.pipe(take(1)).subscribe(() => {
      this.migrationContainer.clear();
    });
  }

  /** Opens the notice */
  openNotice() {
    setTimeout(() => {
      this.noticeModal = this.modalService.new(
        this.noticeModalTemplate,
        this.viewContainerRef
      );
    });
  }

  /** Closes the notice */
  closeNotice() {
    this.noticeModal.dispose();
    localStorage.setItem('noticeClosed', this.notice.uuid);
  }

  /** Stops and clears navigating progress */
  private clearNavigating() {
    if (this.navigatingTimeout) {
      clearTimeout(this.navigatingTimeout);
    }
    this.navigating = false;
  }
}
