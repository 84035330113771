import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Confirm } from '../../models/confirm/confirm';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  public confirmObject: BehaviorSubject<Confirm> = new BehaviorSubject(null);

  private confirmed: BehaviorSubject<boolean>;

 confirm(message: string) {

    // Set the confirm object
    this.confirmObject.next({message});

    // Init confirmed
    this.confirmed = new BehaviorSubject(null);

    // Return as a Promise
    return this.confirmed.toPromise();
  }

  submit(confirmed: boolean) {

    // Set confirmed
    this.confirmed.next(confirmed);
    this.confirmed.complete();

    // Clear the confirm
    this.confirmObject.next(null);
  }
}
