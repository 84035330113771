import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private overlay: Overlay) { }

  /** Creates a new modal */
  new(template: TemplateRef<any>, viewContainerRef: ViewContainerRef): OverlayRef {

    // Create a global position strategy
    const positionStrategy = this.overlay.position().global();

    // Create an overlay reference
    const overlayRef = this.overlay.create({
      width: 'auto',
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: positionStrategy.centerHorizontally().centerVertically()
    });

    // Attach a portal containing the template
    overlayRef.attach(new TemplatePortal(template, viewContainerRef));

    // Return the overlay reference
    return overlayRef;
  }
}
