import { Pipe, PipeTransform } from '@angular/core';
import { UserInvited } from '../models/user/user-invited';

@Pipe({
  name: 'userInvited'
})
export class UserInvitedPipe implements PipeTransform {

  transform(
    invites: UserInvited[],
    inviteOwnerType: 'ARTIST' | 'ORGANISATION' | 'ORGANISATION_CALL',
    pendingOnly?: boolean
  ): UserInvited[] {
    let filtered = invites?.filter(item =>
      item.inviteOwner.type === inviteOwnerType &&
      item.currentState.state !== 'DECLINED'
    ) || [];
    if (pendingOnly) {
      filtered = filtered.filter(item => item.currentState.state === 'PENDING');
    }
    return filtered.sort((a, b) => a.currentState.state === 'PENDING' ? -1 : 1);
  }
}
