import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { ConfigService } from '../services/config/config.service';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  private accessTokenURLs = [
    '/api/auth',
    '/api/artists',
    '/api/genres',
    '/api/organisations',
    '/api/locations',
    '/api/users',
    '/api/callsubmissions',
    '/api/organisationcalls',
    '/api/carousels',
    '/api/playlists',
    '/api/newsletters',
    '/api/reports',
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private authService: AuthService,
    private configService: ConfigService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get the config
    const config = this.configService.get();

    // If the platform is the browser and the request URL needs an access token
    if (
      isPlatformBrowser(this.platformId) &&
      this.accessTokenURLs.some((url) => req.url.indexOf(url) !== -1)
    ) {
      // Get the access token
      const accessToken = this.authService.getAccessToken();
      if (accessToken) {
        // Add the Authorization header
        req = req.clone({
          headers: req.headers.set('X-Authorization', `Bearer ${accessToken}`),
        });
      }
    }

    // If the platform is the server
    else if (
      !isPlatformBrowser(this.platformId) &&
      !req.url.endsWith('/assets/config.json') &&
      req.url !== config.contentUrl
    ) {
      // Prepend the request URL with the back-end host
      req = req.clone({
        url: `${config.backendHost}${req.url}`,
      });
    }

    return next.handle(req);
  }
}
