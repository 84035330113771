import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Howl } from 'howler';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlayerItem } from '../../models/player/player';
import { HumanErrorService } from '../human-error/human-error.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerService implements OnDestroy {

  public current: BehaviorSubject<PlayerItem> = new BehaviorSubject(null);
  public queue: BehaviorSubject<PlayerItem[]> = new BehaviorSubject([]);
  public history: BehaviorSubject<PlayerItem[]> = new BehaviorSubject([]);
  public open: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public playing: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public playerLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public timePassed: BehaviorSubject<number> = new BehaviorSubject(0);
  public openRequested: Subject<void> = new Subject();
  public closeRequested: Subject<void> = new Subject();

  private howl: Howl;
  private playImmediately: boolean;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private router: Router,
    private humanErrorService: HumanErrorService) {

    // Load the locally stored queue and current player item
    if (isPlatformBrowser(this.platformId)) {
      this.loadLocalStorage();
    }

    // Subscribe to the queue
    this.queue.pipe(takeUntil(this.destroyed$)).subscribe(queue => {

      // Add the current URL for new items
      queue.forEach((playerItem, index) => {
        if (!playerItem.url) {
          playerItem = this.addUrlToPlayerItem(playerItem);
          queue[index] = playerItem;
          this.queue.next(queue);
        }
      });

      // Handle it in the local storage
      if (isPlatformBrowser(this.platformId)) {
        this.handleQueueInLocalStorage(queue);
      }
    });

    // Subscribe to the current track
    this.current.pipe(takeUntil(this.destroyed$)).subscribe(playerItem => {

      // Handle it in the local storage
      if (isPlatformBrowser(this.platformId)) {
        this.handleCurrentInLocalStorage(playerItem);
      }

      // If there's already a Howl
      if (this.howl) {

        // Unload it
        this.howl.unload();

        // Reset values
        this.playing.next(false);
        this.loading.next(false);
        this.timePassed.next(0);
      }

      // If a player item with a track and artist is passed
      if (playerItem?.artistTrack && playerItem?.artist) {

        // Create a new Howl
        this.howl = new Howl({
          src: playerItem.artistTrack.url,
          html5: true,
          onplay: () => {
            /**
             * `onplay` doesn't get triggered by external media controls.
             * https://github.com/goldfire/howler.js/issues/1262#issuecomment-576708079
             */
            this.playing.next(true);
            requestAnimationFrame(() => this.updateTimePassed());
          },
          onend: () => {
            this.playing.next(false);
            this.next();
          },
          onload: () => {
            playerItem.artistTrack.duration = this.howl.duration();
            this.loading.next(false);
          },
          onloaderror: () => {
            this.loading.next(false);
            this.humanErrorService.setHumanError({
              title: $localize`Kan track niet afspelen`,
              // eslint-disable-next-line max-len
              message: $localize`De track ${playerItem.artistTrack.title} van ${playerItem.artist.name} kan niet worden afgespeeld. Probeer later opnieuw.`
            });
          },
          onpause: () => {
            this.playing.next(false);
          },
          onstop: () => {
            this.playing.next(false);
          },
          onseek: () => {
            requestAnimationFrame(() => this.updateTimePassed());
          }
        });

        // Set loading
        this.loading.next(this.howl.state() !== 'loaded');

        // If the track should play immediately
        if (this.playImmediately) {

          // Play
          this.howl.play();
        }

        // Unset playImmediately
        this.playImmediately = false;
      }
    });
  }

  /** Updates the time passed */
  updateTimePassed() {
    if (this.howl.state() === 'loaded') {
      const timePassed = (this.howl.seek() as number) || 0;
      this.timePassed.next(timePassed);
    }

    if (this.howl.playing()) {
      requestAnimationFrame(() => this.updateTimePassed());
    }
  }

  /** Immediately plays a player item */
  play(playerItem: PlayerItem) {

    // Add the current track to the history
    this.addCurrentToHistory();

    // Add the current URL if absent
    if (!playerItem.url) {
      playerItem = this.addUrlToPlayerItem(playerItem);
    }

    // Set the current track to play immediately
    this.setCurrent(playerItem, true);

    // Request the player to open
    this.openRequested.next();
  }

  /** Resumes the Howl */
  resume() {
    this.howl?.play();
    this.openRequested.next();
  }

  /** Pauses the Howl */
  pause() {
    this.howl?.pause();
  }

  /** Seeks to a certain percentage of the currently playing track */
  seekByPct(pct: number) {
    this.howl.seek(this.howl.duration() * (pct / 100));
  }

  /** Seeks to a certain position in seconds of the currently playing track */
  seekBySeconds(seconds: number) {
    this.howl.seek(seconds);
  }

  /** Plays the first item in the history */
  back() {
    const history = this.history.value;
    if (history.length) {
      const firstInHistory = history[0];
      this.removeFirstFromHistory();
      this.prependCurrentToQueue();
      this.setCurrent(firstInHistory, true);
    } else {
      this.setCurrent(null);
    }
  }

  /** Plays the next item in the queue */
  next() {
    this.addCurrentToHistory();
    const queue = this.queue.value;
    if (queue.length) {
      this.setCurrent(queue[0], true);
      this.removeFromQueue(0);
    } else {
      this.setCurrent(null);
    }
  }

  /** Adds a player item to the queue */
  addToQueue(playerItem: PlayerItem | PlayerItem[], prepend = false) {

    // Transform into array
    let playerItems: PlayerItem[];
    if (playerItem instanceof Array) {
      playerItems = playerItem;
    } else {
      playerItems = [playerItem];
    }

    // Append or prepend to the queue
    if (!prepend) {
      this.queue.next([...this.queue.value, ...playerItems]);
    } else {
      this.queue.next([...playerItems, ...this.queue.value]);
    }
  }

  /** Removes a track from the queue at a certain index */
  removeFromQueue(index: number) {
    this.queue.next(this.queue.value.filter((track, i) => index !== i));
  }

  /** Removes all tracks from the queue */
  removeAllFromQueue() {
    this.queue.next([]);
  }

  /** Opens the player */
  openPlayer() {
    this.open.next(true);
    localStorage.removeItem('player-closed');
  }

  /** Closes the player */
  closePlayer() {
    this.open.next(false);
    localStorage.setItem('player-closed', 'true');
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /** Sets the current player item if it has an artist and track */
  private setCurrent(playerItem: PlayerItem, playImmediately = false) {
    if (playerItem?.artist && playerItem?.artistTrack) {
      this.playImmediately = playImmediately;
      this.current.next(playerItem);
    } else {
      this.current.next(null);
    }
  }

  /** Adds the current router URL to a player item if it doesn't have a URL yet */
  private addUrlToPlayerItem(playerItem: PlayerItem): PlayerItem {
    if (!playerItem.url) {
      return {
        ...playerItem,
        url: decodeURI(this.router.url)
      };
    } else {
      return playerItem;
    }
  }

  /** Stores (or removes) the queue in local storage  */
  private handleQueueInLocalStorage(queue: PlayerItem[]) {

    // Store in localStorage if there's a queue
    if (queue?.length) {
      window.localStorage.setItem('player-queue', JSON.stringify(queue));
    }

    // Remove from localStorage if there's no queue
    else {
      window.localStorage.removeItem('player-queue');
    }
  }

  /** Stores (or removes) the current player item in local storage  */
  private handleCurrentInLocalStorage(playerItem: PlayerItem) {

    // Store in localStorage if there's a current playerItem
    if (playerItem) {
      window.localStorage.setItem('player-current', JSON.stringify(playerItem));
    }

    // Remove from localStorage if there's no current playerItem
    else {
      window.localStorage.removeItem('player-current');
    }
  }

  /** Loads the locally stored queue and current player item */
  private loadLocalStorage() {

    // If a queue is stored, load it
    const queueStored = window.localStorage.getItem('player-queue');
    if (queueStored) {
      const queue = JSON.parse(queueStored) as PlayerItem[];
      this.queue.next(queue);
    }

    // If a current track is stored, load it
    const currentStored = window.localStorage.getItem('player-current');
    if (currentStored) {
      const currentPlayerItem = JSON.parse(currentStored) as PlayerItem;
      this.setCurrent(currentPlayerItem);
    }
  }

  /** Adds the current track to the queue */
  private prependCurrentToQueue() {
    const current = this.current.value;
    if (current) {
      const currentQueue = this.queue?.value || [];
      this.queue.next([
        current,
        ...currentQueue.slice(0, 4)
      ]);
    }
  }

  /** Removes the first item from the history */
  private removeFirstFromHistory() {
    const currentHistory = this.history?.value || [];
    currentHistory.shift();
    this.history.next(currentHistory);
  }

  /** Adds the current track to the history while limiting it to 100 items */
  private addCurrentToHistory() {
    const current = this.current.value;
    if (current) {
      const currentHistory = this.history?.value || [];
      this.history.next([
        current,
        ...currentHistory
      ]);
    }
  }
}
