import { Pipe, PipeTransform } from '@angular/core';
import { KeyHelper } from '../helpers/key.helper';

@Pipe({
  name: 'key'
})
export class KeyPipe implements PipeTransform {

  constructor(private keyHelper: KeyHelper) {}

  transform(key: string, type: string): string {

    // Return the key value
    return this.keyHelper.getKeyValue(key, type);
  }
}
