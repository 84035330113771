import { QuillConfig } from 'ngx-quill';
import { SwiperOptions } from 'swiper';

export class AppSettings {
  public static readonly paginationLimit = 30;
  public static readonly paginationLimitMax = 100;
  public static readonly paginationUsersLimit = 60;
  public static readonly quill: QuillConfig = {
    theme: 'snow',
    formats: ['bold', 'italic', 'link', 'list'],
    modules: {
      toolbar: [['bold', 'italic', 'link', { list: 'bullet' }]],
      clipboard: { matchVisual: false }, // https://github.com/quilljs/quill/issues/2905#issuecomment-683128521
    },
  };
  public static readonly shortTextMaxLength = 180;
  public static readonly swiperOptions: SwiperOptions = {
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    autoplay: {
      delay: 5000,
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  };
}
