import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Organisation } from '../../models/organisation/organisation';
import { Artist } from '../../models/artist/artist';
import { CacheService } from '../cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private cacheService: CacheService) { }

  /** Gets an Organisation or Artist profile by their slug */
  getProfile(slug: string): Observable<Organisation | Artist> {
    return this.cacheService.get(`/profiles/slug/${encodeURIComponent(slug)}`);
  }
}
