/* eslint-disable @typescript-eslint/naming-convention */
import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';
import {
  NgModule,
  APP_INITIALIZER,
  ErrorHandler,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import {
  APP_BASE_HREF,
  isPlatformBrowser,
  Location,
  PlatformLocation,
  ViewportScroller,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClientJsonpModule,
} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { UrlInterceptor } from './interceptors/url.interceptor';
import { appInitializer } from './helpers/app.initializer';
import { ConfigService } from './services/config/config.service';
import { AuthService } from './services/auth/auth.service';
import { PipesModule } from './pipes/pipes.module';
import { GuardedComponent } from './components/guarded/guarded.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AvatarComponent } from './components/shared/avatar/avatar.component';
import { MenuComponent } from './components/menu/menu.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { Router, Scroll } from '@angular/router';
import { ContentService } from './services/content/content.service';
import { ErrorModalModule } from './components/shared/error-modal/error-modal.module';
import { GlobalErrorHandler } from './handlers/global-error.handler';
import { HeightVariableModule } from './directives/height-variable/height-variable.module';
import { FooterMinimalComponent } from './components/footer-minimal/footer-minimal.component';
import { QuillModule } from 'ngx-quill';
import { AppSettings } from './statics/app.settings';
import { CookieService } from './services/cookie/cookie.service';
import { filter } from 'rxjs';
import { ConfirmModule } from './components/confirm/confirm.module';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    GuardedComponent,
    NotFoundComponent,
    HeaderComponent,
    FooterComponent,
    FooterMinimalComponent,
    AvatarComponent,
    MenuComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    PipesModule,
    OverlayModule,
    ErrorModalModule,
    ConfirmModule,
    HeightVariableModule,
    QuillModule.forRoot(AppSettings.quill),
    HammerModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics, [
      {
        command: 'consent',
        values: [
          'default',
          {
            ad_storage: 'denied',
            analytics_storage: 'denied',
            wait_for_update: 500,
          },
        ],
      },
      {
        command: 'set',
        values: ['ads_data_redaction', true],
      },
    ]),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [ConfigService, ContentService, CookieService, AuthService, Router],
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    Title,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private location: Location
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // Restore the scroll again after a setTimeout to ensure the DOM is ready
      this.router.events
        .pipe(
          filter(
            (event): event is Scroll => event instanceof Scroll && !event.anchor
          )
        )
        .subscribe((e) => {
          // History navigation
          if (e.position) {
            setTimeout(() => {
              this.viewportScroller.scrollToPosition(e.position);
            });
          }

          // Forward navigation
          else {
            // Scroll to the explicit position if it has been set
            const explicitPosition = (this.location.getState() as any)
              ?.scrollPosition;
            if (explicitPosition) {
              setTimeout(() => {
                this.viewportScroller.scrollToPosition(explicitPosition);
              });
            }

            // Otherwise scroll to the top
            else {
              this.viewportScroller.scrollToPosition([0, 0]);
            }
          }
        });
    }
  }
}
