import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BrandedIcons } from '../../models/icon/branded-icon';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  /** Emits a new human readable error */
  getBrandedIcons(): BrandedIcons {
    if (environment.license === 'vibe') {
      return {
        play: 'fak fa-vibe-play',
        pause: 'fak fa-vibe-pause',
        ellipsisH: 'fak fa-vibe-ellipsis-h',
        forwardStep: 'fak fa-vibe-forward',
        backwardStep: 'fak fa-vibe-backward',
        listItem: 'fak fa-vibe-list-item'
      };
    } else {
      return {
        play: 'fas fa-play _icon--fixVerticalCenter',
        pause: 'fas fa-pause _icon--fixVerticalCenter',
        ellipsisH: 'fal fa-lg fa-ellipsis-h _icon--fixVerticalCenter',
        forwardStep: 'fal fa-step-forward _icon--fixVerticalCenter',
        backwardStep: 'fal fa-step-backward _icon--fixVerticalCenter',
        listItem: 'fal fa-sm fa-chevron-right _icon--fixVerticalCenter'
      };
    }
  }
}
