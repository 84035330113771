<div class="_modal" *ngIf="message">
  <header class="_modal-header">
    <h3 class="_modal-title">{{title}}</h3>
  </header>
  <section class="_modal-content">
    <p class="message">{{message}}</p>
  </section>
  <section class="_modal-actions">
    <button
      #confirmButton
      type="button"
      class="_button"
      (click)="submit(true)"
      i18n
    >Bevestig</button>
    <button
      type="button"
      class="_button--outline"
      (click)="submit(false)"
      i18n
    >Annuleer</button>
  </section>
</div>