import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateHelper {

  /** Returns the current date for the user */
  getNowDateForUser() {
    return new Date(Intl.DateTimeFormat('en-US', {
      timeZone: 'Europe/Brussels',
      dateStyle: 'short',
      timeStyle: 'short'
    }).format(new Date()));
  }

  /** Returns the current date for the user as yyyy-MM-dd'T'HH:mm */
  getNowDateAsISO(includeTime = true): string {
    return new Date(this.getNowDateForUser()).toISOString().substring(0, includeTime ? 16 : 10);
  }
}
