import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ConfirmService } from '../../services/confirm/confirm.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements AfterViewInit {

  @ViewChild('confirmButton') confirmButton: ElementRef<any>;

  @Input() title = $localize`Ben je zeker?`;
  @Input() message: string;

  constructor(private confirmService: ConfirmService) {}

  ngAfterViewInit() {
    this.confirmButton.nativeElement.focus();
  }

  submit(confirmed: boolean) {
    this.confirmService.submit(confirmed);
  }
}
