import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationStart, Router, Event as RouterEvent } from '@angular/router';
import { combineLatest, fromEvent } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BrandedIcons } from '../../models/icon/branded-icon';
import { User } from '../../models/user/user';
import { AuthService } from '../../services/auth/auth.service';
import { ContentService } from '../../services/content/content.service';
import { IconService } from '../../services/icon/icon.service';
import { PlayerService } from '../../services/player/player.service';
import { UtilsService } from '../../services/utils/utils.service';
import { ContentMenu } from '../../models/content/content';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appHeader]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @HostBinding('class.app-header--scrolledDown') scrolledDown = false;
  @HostBinding('class.app-header--animatedLogoVIBE') animatedLogoVIBE = false;

  @Output() menuOpened: EventEmitter<any> = new EventEmitter();

  public siteTitle: string;
  public currentUser: User;
  public currentUserPendingInvitesCount: number;
  public menu: ContentMenu;
  public menuPlatformPosition: number;
  public showPlayerButton: boolean;
  public pageIsStandalone: boolean;
  public forceHideSubmenu: boolean;
  public brandedIcons: BrandedIcons;
  public numberOfAcceptedOrganisationCallsInMenu =
    environment.numberOfAcceptedOrganisationCallsInMenu;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private authService: AuthService,
    private contentService: ContentService,
    private playerService: PlayerService,
    private utilsService: UtilsService,
    private iconService: IconService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.brandedIcons = this.iconService.getBrandedIcons();

    // Get the site title
    this.siteTitle = this.contentService.getContentByPath('settings.siteTitle');

    // Get the simple menu
    this.menu = this.contentService.getContentByPath('menus.simple');
    this.menuPlatformPosition = this.contentService.getContentByPath(
      'menus.simplePlatformPosition'
    );

    // Subscribe to whether the current page is standalone
    this.utilsService.pageIsStandalone.subscribe(
      (pageIsStandalone) => (this.pageIsStandalone = pageIsStandalone)
    );

    // Subscribe to the user’s object
    this.authService.currentUser.subscribe((user: User) => {
      // ExpressionChangedAfterItHasBeenCheckedError fix
      setTimeout(() => {
        // Set the current user
        this.currentUser = user;

        // Assign the number of pending invites
        this.currentUserPendingInvitesCount = this.currentUser
          ? this.currentUser.invites?.items.filter(
              (userInvite) => userInvite.currentState.state === 'PENDING'
            )?.length
          : 0;
      });
    });

    // Watch the player service and determine whether the player button should be shown
    combineLatest([
      this.playerService.open,
      this.playerService.current,
      this.playerService.queue,
      this.playerService.playerLoading,
    ]).subscribe((result) => {
      const isOpen = result[0];
      const hasCurrent = !!result[1];
      const hasQueue = result[2]?.length > 0;
      const isPlayerLoading = result[3];
      this.showPlayerButton =
        !isPlayerLoading && !isOpen && (hasCurrent || hasQueue);
    });

    // If the client is the browser & the license is VI.BE
    if (isPlatformBrowser(this.platformId) && environment.license === 'vibe') {
      // Subscribe to window scroll
      fromEvent(window, 'scroll').subscribe(
        (_event) =>
          (this.scrolledDown =
            (window.pageYOffset || document.documentElement.scrollTop) > 0)
      );

      // Subscribe to animated logo shown
      this.utilsService.headerAnimatedLogo.subscribe(
        (headerAnimatedLogo) => (this.animatedLogoVIBE = headerAnimatedLogo)
      );
    }

    // Hide the open submenu when a navigation starts
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.forceHideSubmenu = true;
        setTimeout(() => {
          this.forceHideSubmenu = false;
        }, 50);
      }
    });
  }

  openPlayer() {
    this.playerService.openRequested.next();
  }

  /** Signs out the current user */
  signout() {
    // Navigate to the homepage
    this.router.navigateByUrl('/');

    // Effectively sign out
    this.authService.signout();
  }
}
