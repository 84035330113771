import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorModalComponent } from './error-modal.component';
import { PipesModule } from '../../../pipes/pipes.module';



@NgModule({
  declarations: [
    ErrorModalComponent
  ],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [
    ErrorModalComponent
  ]
})
export class ErrorModalModule { }
