import { environment } from '../../../environments/environment';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { ContentFooter, ContentMenu } from '../../models/content/content';
import { User } from '../../models/user/user';
import { AuthService } from '../../services/auth/auth.service';
import { ContentService } from '../../services/content/content.service';
import { IconService } from '../../services/icon/icon.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentUser: User;
  @Output() menuClosed: EventEmitter<any> = new EventEmitter();

  currentUserName: string;
  contentFooter: ContentFooter;
  contentMenu: ContentMenu;
  contentImage: string;
  brandedIcons = this.iconService.getBrandedIcons();
  numberOfAcceptedOrganisationCallsInMenu =
    environment.numberOfAcceptedOrganisationCallsInMenu;
  menuPlatformPosition = 1;
  menuItemsOpen: string[] =
    JSON.parse(window.localStorage.getItem('menuItemsOpen')) || [];

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private authService: AuthService,
    private contentService: ContentService,
    private iconService: IconService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Get the content
    this.contentFooter = this.contentService.getContentByPath('footer');
    this.contentMenu = this.contentService.getContentByPath('menus.full');
    this.contentImage = this.contentService.getContentByPath('menus.fullImage');
    this.menuPlatformPosition =
      this.contentService.getContentByPath(
        'menus.fullscreenPlatformPosition'
      ) || 1;
  }

  ngOnChanges() {
    // If there's a current user, join his or her given and family name
    if (this.currentUser) {
      this.currentUserName = [
        this.currentUser.givenName,
        this.currentUser.familyName,
      ].join(' ');
    }
  }

  /** Signs out the current user */
  signout() {
    // Effectively sign out
    this.authService.signout();

    // Navigate to the homepage
    this.router.navigateByUrl('/');

    // Close the menu
    this.menuClosed.emit();
  }

  toggleMenuItem(label: string) {
    if (this.menuItemsOpen.includes(label)) {
      this.menuItemsOpen = this.menuItemsOpen.filter((item) => item !== label);
    } else {
      this.menuItemsOpen.push(label);
    }
    window.localStorage.setItem(
      'menuItemsOpen',
      JSON.stringify(this.menuItemsOpen)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
