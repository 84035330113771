import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  // Initialize Sentry when on production or test
  if (environment.production && environment.sentry?.dsn) {
    const ignoreErrors = [
      'Non-Error exception captured',
      'InvalidTokenError',
      'ChunkLoadError',
      'Loading chunk',
      'ResizeObserver',
      'cookiefirst',
      'banner.js',
      '[CF]',
      'Java exception was raised during method invocation',
      'Non-Error promise rejection captured with value',
      'Failed to start the audio device',
      'NS_ERROR_ABORT',
      '_reportEvent is not defined',
    ];

    Sentry.init({
      dsn: environment.sentry.dsn,
      ignoreErrors,
      environment: document.URL.includes('https://test.vi.be')
        ? 'test'
        : 'production',
      beforeSend: (event, hint) => {
        if (
          ignoreErrors.some(
            (exclude) =>
              event.exception?.values[0]?.value?.includes(exclude) ||
              hint.originalException?.toString()?.includes(exclude)
          )
        ) {
          console.warn('Excluded error from being sent to Sentry', event, hint);
          return null;
        }
        return event;
      },
    });
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
