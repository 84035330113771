import { Injectable } from '@angular/core';
import { AppSettings } from '../statics/app.settings';
import { EntityList } from '../models/entity-list/entity-list';
import { CacheService } from '../services/cache/cache.service';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { createHttpParams } from './utils';

@Injectable({
  providedIn: 'root'
})
export class EntityListHelper {

  constructor(private cacheService: CacheService) {}

  /**
   *  Fetches entities of an endpoint using offset and limit. Passing 0 as limit means infinite. Use wisely.
   */
  get<T>(
    endpoint: string,
    offset: number,
    limit: number,
    extraParams: {[key: string]: string | boolean | any[]} = {}
  ): Observable<EntityList<T>> {

    // Limit is infinite
    if (limit === 0) {

      // Get first batch with a maximum of items
      return this.cacheService.get(
        endpoint,
        createHttpParams({
          offset: `0`,
          limit: `${AppSettings.paginationLimitMax}`,
          ...extraParams
        })
      ).pipe(switchMap((entityListFirstBatch: EntityList<any>) => {

        // Container for the final entity list
        const entityList: EntityList<any> = {
          items: [],
          total: entityListFirstBatch.total
        };

        // If there are no items, return an empty entity list
        if (!entityListFirstBatch.total) {
          return of(entityList);
        }

        // Container for the requests
        const requests: Observable<EntityList<any>>[] = [];

        // Get the total number of pages
        const numberOfPages = Math.ceil(entityListFirstBatch.total / AppSettings.paginationLimitMax);

        // Add a request for each page
        [...Array(numberOfPages)].forEach((_, i) => {
          requests.push(this.cacheService.get(
            endpoint,
            createHttpParams({
              offset: `${AppSettings.paginationLimitMax * i}`,
              limit: `${AppSettings.paginationLimitMax}`,
              ...extraParams
            })
          ));
        });

        // Return the responses of the joined requests as one EntityList
        return forkJoin(requests).pipe(map((responses) => {
          responses.forEach(response => {
            entityList.items = [
              ...entityList.items,
              ...response.items];
          });
          return entityList;
        }));
      }));
    }

    // Limit is finite
    else {

      // Return the regular single cached request
      return this.cacheService.get(
        endpoint,
        createHttpParams({
          offset: `${offset}`,
          limit: `${limit}`,
          ...extraParams
        })
      );
    }
  }
}
