import { Injectable } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import { ContentService } from '../services/content/content.service';

@Injectable({
  providedIn: 'root'
})
export class PageCanMatch  {

  constructor(private contentService: ContentService) {}

  canMatch(route: Route, segments: UrlSegment[]) {
    const page = segments[1].path;
    const pageHTML = this.contentService.getContentByPath(`pages.${page}`);
    return pageHTML ? true : false;
  }
}
