import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { Cache } from '../../models/cache/cache';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  /** Container for all caches */
  private caches: {[key: string]: Cache} = {};

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private config: ConfigService,
    private http: HttpClient) {}

  /** Gets the cache based on the passed params */
  get(
    request: string,
    params?: HttpParams,
    excludeBackendUrl = false): Observable<any> {

    // Build the key
    let key = request;
    if (params) {
      key = `${request}?${params.toString()}`;
    }

    // If no cache exists
    if (!this.caches[key]) {

      // Add the cache
      this.caches[key] = new Cache(
        this.caches,
        key,
        this.http
          .get(`${excludeBackendUrl ? '' : this.config.get().backendUrl}${request}`, {params})
          .pipe(
            tap(() => null, () => null, () => false ? console.log('Completed: ', key) : null),
            shareReplay()),
        isPlatformBrowser(this.platformId) ? undefined : 0 // setTimeout doesn't exist on Node, so don't set a timer on the server
      );
    }

    // Return the cache
    return this.caches[key].cache;
  }

  /** Clears a cache entry by its key, including the query param variants and, if asked, its descendants */
  clear(key: string, includeDescendants = false): void {
    Object.entries(this.caches).forEach(([keyPredicate, _value]) => {
      if (keyPredicate === key || keyPredicate.startsWith(includeDescendants ? key : `${key}?`)) {
        this.caches[keyPredicate].clear();
      }
    });
  }

  /** Clears a cache entry if its key includes the passed string */
  clearIncludes(wildcard: string): void {
    Object.entries(this.caches).forEach(([keyPredicate, value]) => {
      if (keyPredicate.includes(wildcard)) {
        this.caches[keyPredicate].clear();
      }
    });
  }

  /** Clears the entire cache */
  clearAll(): void {
    this.caches = {};
  }
}
