import { Component, HostBinding, Input, OnChanges } from '@angular/core';
import { User } from '../../../models/user/user';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {

  @HostBinding('[attr.title]') fullName: string;
  @HostBinding('class.avatar--light') @Input() light = false;
  @Input() user: User;

  public initial: string;

  ngOnChanges(): void {

    if (this.user) {

      // Combine given and family name into full name
      if (this.user.givenName || this.user.familyName) {
        this.fullName = [this.user.givenName, this.user.familyName].join(' ').trim();
      }

      // Assign initials if the user doesn't have an image
      if (!this.user.image) {
        this.initial = this.user.givenName?.slice(0,1);
      }
    }
  }
}
