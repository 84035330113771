import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HumanError } from '../../models/human-error/human-error';

@Injectable({
  providedIn: 'root'
})
export class HumanErrorService {

  public humanError: BehaviorSubject<HumanError> = new BehaviorSubject(null);

  /** Emits a new human readable error */
  setHumanError(humanError: HumanError) {
    if (humanError && !humanError.message) {
      humanError.message = $localize`Bad vibes! De bewerking kon niet worden voltooid.`;
    }
    this.humanError.next(humanError);
  }
}
