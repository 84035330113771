import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Config } from '../../models/config/config';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  public inited: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public functionalAccepted: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /** Appends the CookieFirst script */
  load(config: Config) {
    if (config.cookieFirstKey) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.setAttribute('data-cookiefirst-key', config.cookieFirstKey);
      script.src = 'https://consent.cookiefirst.com/banner.js';
      document.body.appendChild(script);
    }
  }
}
