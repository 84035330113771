import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  handleError(error: any): void {
    // If it's a chunk load error, reload the window to get the latest code
    const chunkFailedMessage = /ChunkLoadError: Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    // If we're on production
    else if (environment.production === true) {
      // Capture the error on Sentry
      Sentry.captureException(error.originalError || error);
    }

    // If it’s the browser, throw the error
    if (isPlatformBrowser(this.platformId)) {
      throw error;
    }
    // If it's the server, log the error
    else {
      console.log(error);
    }
  }
}
