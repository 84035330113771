<h1 class="logo">
  <a
    class="logo-link"
    href="/"
    tabindex="1"
    title="{{siteTitle}}">
    <span class="logo-text">{{siteTitle}}</span>
    <img class="logo-img" src="./assets/logo-main.svg" loading="eager" />
    <img *ngIf="animatedLogoVIBE" class="logo-img--animatedLogoVIBE" src="./assets/logo-animated.gif" loading="eager" />
  </a>
</h1>

<ul class="menu" [class.menu--forceHideSubmenu]="forceHideSubmenu">
  <ng-container *ngIf="!pageIsStandalone">

    <!-- Default -->
    <ng-container *ngIf="!menu?.length">
      <!-- Basics -->
      <li class="menu-item--hideWhenNarrow">
        <a
          routerLink="/"
          class="menu-item-main"
          routerLinkActive="menu-item-main--active"
          [routerLinkActiveOptions]="{exact: true}">
          <span class="menu-item-text" i18n>Home</span>
        </a>
      </li>
      <li class="menu-item--hideWhenNarrow">
        <a
          routerLink="/calls"
          class="menu-item-main"
          routerLinkActive="menu-item-main--active">
          <span class="menu-item-text" i18n>Kansen</span>
        </a>
      </li>
      <li class="menu-item--hideWhenNarrow">
        <a
          routerLink="/artists"
          class="menu-item-main"
          routerLinkActive="menu-item-main--active">
          <span class="menu-item-text" i18n>Artiesten</span>
        </a>
      </li>

      <ng-container *ngIf="!currentUser">
        <li class="menu-item menu-item--hideWhenNarrow menu-item--hasSubmenu">
          <a
            class="menu-item-main"
            routerLink="/auth"
            routerLinkActive="menu-item-main--active">
            <span class="menu-item-text">Aanmelden <i
              class="menu-item-main-submenuIcon fas fa-chevron-down"
            ></i></span>
          </a>
          <div class="submenu">
            <ul class="submenu-list">
              <li class="submenu-item">
                <a
                  routerLink="/auth/signin"
                  class="submenu-item-main"
                  routerLinkActive="submenu-item-main--active">
                  <span class="submenu-item-text" i18n>Log in</span>
                </a>
              </li>
              <li class="submenu-item">
                <a
                  routerLink="/auth/signup"
                  class="submenu-item-main"
                  routerLinkActive="submenu-item-active">
                  <span class="submenu-item-text" i18n="header|">Registreer</span>
                </a>
              </li>              
            </ul>
          </div>
        </li>
      </ng-container>

      <ng-container *ngIf="currentUser">
        <li
          *ngIf="currentUser?.roles?.includes('ADMIN')"
          class="menu-item--hideWhenNarrow">
          <a
            routerLink="/admin"
            class="menu-item-main"
            routerLinkActive="menu-item-main--active">
            <span class="menu-item-text" i18n>Admin</span>
          </a>
        </li>
        <li class="menu-item menu-item--hideWhenNarrow menu-item--hasSubmenu">
          <a
            class="menu-item-main--preserveCasing menu-item-main--user"
            routerLink="/auth/account"
            routerLinkActive="menu-item-main--active">
            <div class="user">
              <app-avatar [user]="currentUser" class="user-avatar"></app-avatar>
              <p class="user-name">{{currentUser.givenName}}</p>
            </div>
          </a>
          <div class="submenu">
            <ul class="submenu-list">
              <li class="submenu-item">
                <a
                  routerLink="/auth/account"
                  class="submenu-item-main"
                  routerLinkActive="submenu-item-main--active">
                  <span class="submenu-item-text" i18n>Account</span>
                </a>
              </li>
              <li class="submenu-item">
                <a
                  routerLink="/auth/account/playlists"
                  class="submenu-item-main"
                  routerLinkActive="submenu-item-main--active">
                  <span class="submenu-item-text" i18n>Playlists</span>
                </a>
              </li>
              <li class="submenu-item">
                <a
                  routerLink="/auth/account/profiles"
                  class="submenu-item-main"
                  routerLinkActive="submenu-item-main--active">
                  <span class="submenu-item-text" i18n>Profielen</span>
                </a>
                <ul class="submenu-item-list">
                  <ng-template [ngTemplateOutlet]="artistProfiles"></ng-template>
                  <ng-template [ngTemplateOutlet]="organisationProfiles"></ng-template>
                </ul>
              </li>
              <li class="submenu-item" *ngIf="(currentUser?.invites?.items | userInvited:'ORGANISATION_CALL':true)?.length || currentUser?.acceptedOrganisationCalls?.items?.length">
                <span class="submenu-item-main">
                  <span class="submenu-item-text" i18n>Kansen</span>
                </span>
                <ul class="submenu-item-list">
                  <ng-template [ngTemplateOutlet]="pendingCalls"></ng-template>
                  <ng-template [ngTemplateOutlet]="acceptedCalls"></ng-template>
                </ul>
              </li>
              <li class="submenu-item">
                <button
                  class="submenu-item-main"
                  (click)="signout()">
                  <span class="submenu-item-text" i18n>Uitloggen</span>
                </button>
              </li>
            </ul>
          </div>
        </li>
      </ng-container>      
    </ng-container>
    
    <!-- Custom -->
    <li
      *ngIf="menu?.length"
      class="menu-item--hideWhenNarrow menu-item--hasSubmenu"
      [style.--menu-item-position]="menuPlatformPosition || 1">
      <span
        class="menu-item-main menu-item-main--active"
        routerLinkActive="menu-item-main--active">
        <ng-container i18n>Platform</ng-container><i
        class="menu-item-main-submenuIcon fas fa-chevron-down"
      ></i></span>
      <div class="submenu">
        <ul class="submenu-list">
          
          <!-- Basics -->
          <li class="submenu-item">
            <a
              routerLink="/calls"
              class="submenu-item-main"
              routerLinkActive="submenu-item-main--active">
              <span class="submenu-item-text" i18n>Kansen</span>
            </a>
          </li>
          <li class="submenu-item">
            <a
              routerLink="/artists"
              class="submenu-item-main"
              routerLinkActive="submenu-item-main--active">
              <span class="submenu-item-text" i18n>Artiesten</span>
            </a>
          </li>
          <li class="submenu-item">
            <a
              routerLink="/"
              class="submenu-item-main"
              routerLinkActive="submenu-item-main--active">
              <span class="submenu-item-text" i18n>About</span>
            </a>
          </li>

          <!-- Current user -->
          <ng-container *ngIf="currentUser">

            <li class="submenu-item" *ngIf="currentUser?.roles?.includes('ADMIN')">
              <a
                routerLink="/admin"
                class="submenu-item-main"
                routerLinkActive="submenu-item-main--active">
                <span class="submenu-item-text" i18n>Admin</span>
              </a>
            </li>

            <!-- Auth -->
            <li class="submenu-item">
              <a
                class="submenu-item-main--preserveCasing submenu-item-main--user"
                routerLink="/auth/account"
                routerLinkActive="submenu-item-main--active">
                <div class="user">
                  <app-avatar [user]="currentUser" class="user-avatar"></app-avatar>
                  <p class="user-name">{{currentUser.givenName}}</p>
                </div>
              </a>
              <ul class="submenu-item-list">
                <ng-template [ngTemplateOutlet]="artistProfiles"></ng-template>
                <ng-template [ngTemplateOutlet]="organisationProfiles"></ng-template>
                <ng-template [ngTemplateOutlet]="pendingCalls"></ng-template>
                <ng-template [ngTemplateOutlet]="acceptedCalls"></ng-template>
              </ul>
            </li>

            <!-- Signout -->
            <li class="submenu-item">
              <button
                class="submenu-item-main"
                (click)="signout()">
                <span class="submenu-item-text" i18n>Uitloggen</span>
              </button>
            </li>
          </ng-container>

          <!-- Login -->
          <li class="submenu-item" *ngIf="!currentUser">
            <a
              class="submenu-item-main"
              routerLink="/auth/signin"
              routerLinkActive="submenu-item-active"
            ><span class="submenu-item-text" i18n>Log in</span></a>
          </li>

          <!-- Register -->
          <li class="submenu-item" *ngIf="!currentUser">
            <a
              class="submenu-item-main"
              routerLink="/auth/signup"
              routerLinkActive="submenu-item-active"
            ><span class="submenu-item-text" i18n="header|">Registreer</span></a>
          </li>
        </ul>
      </div>
    </li>

    <!-- Editorial -->
    <li
      *ngFor="let menuItem of menu; let i = index"
      class="menu-item--hideWhenNarrow"
      [class.menu-item--hasSubmenu]="menuItem.submenu?.length"
      [style.--menu-item-position]="i + 1">
      <a
        *ngIf="menuItem.routerLink"
        [routerLink]="menuItem.routerLink || null"
        [routerLinkActive]="'menu-item-main--active'"
        class="menu-item-main--preserveCasing"
      >{{menuItem.label}}<i
        *ngIf="menuItem.submenu?.length"
        class="menu-item-main-submenuIcon fas fa-chevron-down"
      ></i></a>
      <a
        *ngIf="menuItem.link && !menuItem.routerLink"
        [href]="menuItem.link"
        class="menu-item-main--preserveCasing"
      >{{menuItem.label}}<i
        *ngIf="menuItem.submenu?.length"
        class="menu-item-main-submenuIcon fas fa-chevron-down"
      ></i></a>
      <span
        *ngIf="!menuItem.routerLink && !menuItem.link"
        class="menu-item-main--preserveCasing"
      >{{menuItem.label}}<i
        *ngIf="menuItem.submenu?.length"
        class="menu-item-main-submenuIcon fas fa-chevron-down"
      ></i></span>
      <div
        *ngIf="menuItem.submenu?.length"
        class="submenu">
        <ul class="submenu-list">
          <li
            *ngFor="let submenuItem of menuItem.submenu"
            class="submenu-item">
            <a
              *ngIf="submenuItem.routerLink"
              [routerLink]="submenuItem.routerLink || null"
              [routerLinkActive]="'submenu-item-main--active'"
              [routerLinkActiveOptions]="{exact: submenuItem.routerLink === '/'}"
              class="submenu-item-main--preserveCasing"
            ><span class="submenu-item-text">{{submenuItem.label}}</span></a>
            <a
              *ngIf="submenuItem.link && !submenuItem.routerLink"
              [href]="submenuItem.link"
              class="submenu-item-main--preserveCasing"
            ><span class="submenu-item-text">{{submenuItem.label}}</span></a>
            <span
              *ngIf="!submenuItem.routerLink && !submenuItem.link"
              class="submenu-item-main--preserveCasing"
            ><span class="submenu-item-text">{{submenuItem.label}}</span></span>
          </li>
        </ul>
      </div>
    </li>
  </ng-container>

  <!-- The page is standalone -->
  <ng-container *ngIf="pageIsStandalone">

    <!-- Logout -->
    <li class="menu-item" *ngIf="currentUser">
      <button
        class="menu-item-main"
        (click)="signout()"
        i18n
      >Uitloggen</button>
    </li>
  </ng-container>

  <!-- Player -->
  <li class="menu-item" *ngIf="showPlayerButton">
    <button
      type="button"
      title="Open muziekspeler"
      class="menu-item-main--hasIcon"
      i18n-title
      (click)="openPlayer()">
      <i class="menu-item-main-icon {{brandedIcons.play}}"></i>
    </button>
  </li>

  <!-- Bars -->
  <li class="menu-item" *ngIf="!pageIsStandalone">
    <button
      type="button"
      class="menu-buttonMenuOpen menu-item-main--hasIcon"
      [class.menu-item-main--hasBadge]="currentUser && currentUserPendingInvitesCount"
      title="Menu"
      i18n-title
      (click)="menuOpened.emit()">
      <i class="menu-item-main-icon fal fa-bars"></i>
    </button>
  </li>
</ul>

<ng-template #artistProfiles>
  <li class="submenu-item" *ngFor="let invite of (currentUser?.invites?.items | userInvited: 'ARTIST')">
    <a
      class="submenu-item-main--preserveCasing"
      routerLink="/{{invite.inviteOwner.slug}}"
      routerLinkActive="submenu-item-main--active">
      <i class="submenu-item-icon fa-fw {{brandedIcons.listItem}}"></i>
      <span class="submenu-item-text">{{invite.inviteOwner.name}}</span><span
        *ngIf="invite.currentState.state === 'PENDING'"
        class="submenu-item-badge"
        title="Je bent uitgenodigd"
        i18n-title
      ></span>
      </a>
  </li>
</ng-template>

<ng-template #organisationProfiles>
  <li class="submenu-item" *ngFor="let invite of (currentUser?.invites?.items | userInvited: 'ORGANISATION')">
    <a
      class="submenu-item-main--preserveCasing"
      routerLink="/{{invite.inviteOwner.slug}}"
      routerLinkActive="submenu-item-main--active">
      <i class="submenu-item-icon fa-fw {{brandedIcons.listItem}}"></i>
      <span class="submenu-item-text">{{invite.inviteOwner.name}}</span>
      <span *ngIf="invite.currentState.state === 'PENDING'" class="submenu-item-badge" title="Je bent uitgenodigd"></span>
      </a>
  </li>
</ng-template>

<ng-template #pendingCalls>
  <li
    class="submenu-item"
    *ngFor="let invite of (currentUser?.invites?.items | userInvited:'ORGANISATION_CALL':true) | orderBy:'asc':'datePublished'">
    <a
      class="submenu-item-main--preserveCasing"
      routerLink="/{{invite.inviteOwner.organisationSlug}}/calls/{{invite.inviteOwner.slug}}"
      routerLinkActive="submenu-item-main--active"
      title="{{invite.inviteOwner.title}}">
      <i class="submenu-item-icon fa-fw {{brandedIcons.listItem}}"></i>
      <span class="submenu-item-text">{{invite.inviteOwner.title}}</span>
      <span
        class="submenu-item-badge"
        title="Je bent uitgenodigd"
        i18n-title
      ></span>
      </a>
  </li>
</ng-template>

<ng-template #acceptedCalls>
  <!-- Accepted Organisation Calls -->
  <li
    class="submenu-item"
    *ngFor="let organisationCall of currentUser?.acceptedOrganisationCalls?.items | slice:0:numberOfAcceptedOrganisationCallsInMenu">
    <a
      class="submenu-item-main--preserveCasing"
      routerLink="/{{organisationCall.organisation.slug}}/calls/{{organisationCall.slug}}"
      routerLinkActive="submenu-item-main--active"
      title="{{organisationCall.title}}">
      <i class="submenu-item-icon fa-fw {{brandedIcons.listItem}}"></i>
      <span class="submenu-item-text">{{organisationCall.title}}</span>
    </a>
  </li>
</ng-template>