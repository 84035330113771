export const environment = {
  production: true,
  license: 'vibe',
  sentry: {
    dsn: 'https://9bd21ca3be29452eab1ef763801fb46f@o1003497.ingest.sentry.io/5963962',
  },
  googleAnalytics: 'G-WM26WLL6KZ',
  allowSubmissionsToViewOtherSubmissions: true,
  numberOfAcceptedOrganisationCallsInMenu: 2,
  collectiveRightsManagementEnabled: true,
};
