import { take } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { RefreshSessionResponse } from '../models/auth/refresh-session-response';
import { PrepareCurrentUserResponse } from '../models/auth/prepare-current-user-response';
import { ConfigService } from '../services/config/config.service';
import { ContentService } from '../services/content/content.service';
import { Router } from '@angular/router';
import { CookieService } from '../services/cookie/cookie.service';

export const appInitializer =
  (
    configService: ConfigService,
    contentService: ContentService,
    cookieService: CookieService,
    authService: AuthService,
    router: Router
  ) =>
  () =>
    new Promise<void>((resolve) => {
      /**
       * @NOTE `isPlatformBrowser(this.platformId)` always returns false for some reason, so using `window !== 'undefined'`
       */
      const isPlatformBrowser: boolean = typeof window !== 'undefined';

      // Load configuration
      configService.loadConfig(isPlatformBrowser).subscribe((config) => {
        // Load the content
        contentService.loadContent().subscribe(
          () => {
            // The eventual function to be executed when a condition is met
            const goForIt = () => {
              router.initialNavigation();
              resolve();
            };

            // Browser
            if (isPlatformBrowser) {
              // CookieFirst
              window.addEventListener('CookieFirstInited', (e) => {
                cookieService.inited.next(true);
              });
              window.addEventListener('CookieFirstFunctionalAccepted', (e) => {
                cookieService.functionalAccepted.next(true);
              });
              cookieService.load(config);

              // Access token
              const accessToken = authService.getAccessToken();
              if (accessToken) {
                const isAccessTokenExpired = authService.isAccessTokenExpired();

                // If the accessToken has expired
                if (isAccessTokenExpired) {
                  // Refresh the session
                  authService
                    .refreshSession()
                    .pipe(take(2))
                    .subscribe(
                      (refreshSessionResponse: RefreshSessionResponse) => {
                        if (refreshSessionResponse !== 'pending') {
                          goForIt();
                        }
                      }
                    );
                }

                // If the accessToken is fresh
                else {
                  // Prepare the user
                  authService
                    .prepareCurrentUser()
                    .pipe(take(2))
                    .subscribe(
                      (
                        prepareCurrentUserResponse: PrepareCurrentUserResponse
                      ) => {
                        if (prepareCurrentUserResponse !== 'pending') {
                          goForIt();
                        }
                      }
                    );
                }
              }

              // If there's no accessToken
              else {
                goForIt();
              }
            }

            // Server
            else {
              goForIt();
            }
          },
          () => {
            router.initialNavigation();
            resolve();
          }
        );
      });
    });
