<ng-container *ngIf="isContentLoaded">
  <div class="navigating" *ngIf="navigating"></div>

  <header
    appHeader
    *ngIf="!pageIsFullScreen"
    (menuOpened)="menuOpened = true"
    class="header"
    [class.header--unsticky]="headerUnsticky"
    [appHeightVariable]="{ name: 'heightAppHeader', setToRoot: true }"
  ></header>

  <app-menu
    *ngIf="menuOpened"
    [currentUser]="currentUser"
    (menuClosed)="menuOpened = false"
  ></app-menu>

  <main [attr.data-cy-loggedin]="currentUser?.uuid">
    <router-outlet></router-outlet>
  </main>

  <ng-container *ngIf="!pageIsStandalone && !pageIsFullScreen">
    <footer *ngIf="!pageHasMinimalFooter" appFooter></footer>
    <footer *ngIf="pageHasMinimalFooter" appFooterMinimal></footer>
  </ng-container>

  <ng-template #playerContainer></ng-template>

  <ng-template #migrationContainer></ng-template>

  <ng-template #humanServerErrorModalTemplate>
    <app-error-modal
      [humanError]="humanServerError"
      (closed)="clearServerError()"
    ></app-error-modal>
  </ng-template>

  <ng-template #confirmModalTemplate>
    <app-confirm [message]="confirm.message"></app-confirm>
  </ng-template>

  <ng-template #noticeModalTemplate *ngIf="notice">
    <div class="_modal--notice">
      <header class="_modal-header">
        <h1 class="_modal-title" i18n>{{ notice.title }}</h1>
      </header>
      <section class="_modal-content">
        <div class="_text" [innerHTML]="notice.text"></div>
      </section>
      <section class="_modal-actions" *ngIf="!notice.preventClose">
        <button
          type="button"
          (click)="closeNotice()"
          class="_button--notice"
          i18n
        >
          Sluiten
        </button>
      </section>
    </div>
  </ng-template>
</ng-container>
<ng-container *ngIf="!isContentLoaded">
  <main>
    <router-outlet></router-outlet>
  </main>
</ng-container>
