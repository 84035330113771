import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserHelper {

  constructor(private authService: AuthService) { }

  /** Determines whether the current user is an admin */
  isCurrentUserAdmin() {
    return this.authService.currentUser.value?.roles?.includes('ADMIN');
  }

  /** Determines whether the current user has accepted or pending invites */
  hasCurrentUserAcceptedOrPendingInvites() {
    return (this.authService.currentUser.value?.invites?.items || [])
      .some(invite => invite.currentState.state !== 'DECLINED');
  }

  /** Determines whether the current user has to finalize */
  shouldCurrentUserFinalize() {
    const currentUser = this.authService.currentUser.value;
    if (this.isCurrentUserAdmin()) {
      return false;
    }
    if (this.hasCurrentUserAcceptedOrPendingInvites()) {
      return false;
    }
    if (currentUser?.skippedFinalize) {
      return false;
    }
    if (currentUser?.v2Id) {
      return false;
    }
    return true;
  }

  /** Determines whether the current user has to complete the migration. */
  shouldCurrentUserCompleteMigration() {
    const currentUser = this.authService.currentUser.value;
    return currentUser?.v2Id && !currentUser.completedMigration;
  }
}
