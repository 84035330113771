import { Component, HostBinding, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ContentFooter } from '../../models/content/content';
import { ContentService } from '../../services/content/content.service';
import { CookieService } from '../../services/cookie/cookie.service';
import { PlayerService } from '../../services/player/player.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appFooter]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @HostBinding('class.footer--playerOpen') playerOpen = false;

  public dateNow = new Date();
  public siteTitle: string;
  public currentURLForSwitching: string;
  public copyrightLong: string;
  public content: ContentFooter;
  public cookiesInited = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    @Inject(LOCALE_ID) public localeId: string,
    private router: Router,
    private playerService: PlayerService,
    private contentService: ContentService,
    private cookieService: CookieService) { }

  ngOnInit(): void {

    // Get the content
    this.siteTitle = this.contentService.getContentByPath('settings.siteTitle');
    this.content = this.contentService.getContentByPath('footer');
    this.copyrightLong = this.contentService.getContentByPath('settings.copyrightLong');

    // Subscribes to the router events to get the current URL for switching the language
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {

      // Remove '/en' from the current URL so a language switch can be made
      this.currentURLForSwitching = event.urlAfterRedirects.replace('/en', '');

    });

    // Subscribe to whether the player is open
    this.playerService.open
      .pipe(takeUntil(this.destroyed$))
      .subscribe(open => this.playerOpen = open);

    // Subscribe to whether the cookies are inited
    this.cookieService.inited
      .pipe(takeUntil(this.destroyed$))
      .subscribe(inited => this.cookiesInited = inited);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
