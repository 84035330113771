import {
  Injectable,
  Inject,
  PLATFORM_ID,
  TransferState,
  StateKey,
} from '@angular/core';
import {
  isPlatformServer,
  isPlatformBrowser,
  APP_BASE_HREF,
} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  map,
  take,
  startWith,
  first,
  switchMap,
  catchError,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  /** Whether the current page is full screen and has the header and footer hidden */
  public pageIsFullScreen: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  /** Whether the current page is standalone */
  public pageIsStandalone: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  /** Whether the current page has a minimal footer */
  public pageHasMinimalFooter: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  /** Whether the main header is sticky */
  public headerSticky: BehaviorSubject<boolean> = new BehaviorSubject(null);

  /** Whether the header has the animated VI.BE logo */
  public headerAnimatedLogo: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  /** Whether the Google Maps API has loaded */
  private googleMapsAPILoaded = false;

  constructor(
    private state: TransferState,
    private httpClient: HttpClient,
    @Inject(APP_BASE_HREF) private baseHref: string,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  /**
   * Caches the data set by the server, so the browser can fetch it afterwards,
   * making an extra call on the client unneccessary. The cache is immediately removed
   * when fetched by the front-end to make sure subsequent calls are freshly fetched.
   */
  getServerCachedObservable(
    $dataSource: Observable<any>,
    stateKey: StateKey<any>
  ) {
    if (isPlatformServer(this.platformId)) {
      return $dataSource.pipe(
        map((datum) => {
          this.state.set(stateKey, datum);
          return datum;
        }),
        take(1)
      );
    } else if (isPlatformBrowser(this.platformId)) {
      const savedValue = this.state.get(stateKey, null);
      if (savedValue) {
        this.state.remove(stateKey);
        return $dataSource.pipe(startWith(savedValue), take(1));
      } else {
        return $dataSource;
      }
    }
  }

  /** Loads the Google Maps API if necessary and returns whether it has been loaded */
  loadGoogleMapsAPI(): Observable<boolean> {
    // If we’re on the server, return false
    if (!isPlatformBrowser(this.platformId)) {
      return of(false);
    }

    // If the Google Maps API was already loaded, return true
    if (this.googleMapsAPILoaded) {
      return of(true);
    }

    // Otherwise load the API
    return this.httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyCwT_OirFaa-UhnkEWQ1HmAky5SPvK0iyI&libraries=places&language=nl-BE',
        'callback'
      )
      .pipe(
        first(),
        switchMap(() => {
          // All’s good, set loaded and return true
          this.googleMapsAPILoaded = true;
          return of(true);
        }),
        // Failed to load the API, return false
        catchError(() => of(false))
      );
  }

  /** Gets the domain and baseHref of the current environment, f.e. `vi.be/platform/` */
  getURLRoot() {
    return isPlatformBrowser(this.platformId)
      ? `${location.origin}${this.baseHref}`.replace(/^https?:\/\//, '')
      : '';
  }
}
